import React from "react";

function AcessRulesPT() {
    return (
        <>
            {/* <p>Programe sua visita com segurança e sem imprevistos.</p> */}

            <h6 className="my-4"><b>Não é permitido:</b></h6>

            <ul className="list-acess-rules">
                <li>Filmar ou Fotografar para fins comerciais sem a prévia autorização da empresa.</li>
                <li>Portar ou utilizar Drones.</li>
                <li>Acessar com materiais de grande porte (ex.: tripé, instrumentos musicais, guarda-sol, cadeira de praia, cooler/isopor, fantasias volumosas, malas ou equipamentos acima de 25 x 35 x 55 cm).</li>
                <li>Acessar ou permanecer sem camisa, com trajes de banho ou descalço.</li>
                <li>Comer ou beber dentro dos bondes ou trazer alimentos sem embalagem lacrada.</li>
                <li>Acessar com skate, patinete, patins ou sapatos com rodas embutidas, bicicleta ou similares.</li>
                <li>Acessar com bola, bexiga ou balão.</li>
                <li>Criança ou adolescente menor de 16 (dezesseis) anos acessar ou se manter no parque desacompanhado dos pais ou responsáveis maiores de idade. Os responsáveis pelos menores devem apresentar documentação comprobatória da relação de parentesco de até terceiro grau (pais, mães, irmãos, tios, sobrinhos, avós), ou autorização dos pais, conforme previsto em lei.</li>
                <li>Plantar ou retirar espécies da flora.</li>
                <li>Praticar qualquer tipo de depredação do meio ambiente.</li>
                <li>Alimentar, se aproximar ou caçar os animais.</li>
                <li>Portar armas de fogo, munição, dispositivos de autodefesa (spray de pimenta, armas de choque), objetos cortantes ou pontiagudos.</li>
                <li>Acessar com objetos ou líquidos inflamáveis ou explosivos.</li>
                <li>Som alto, utilizar cornetas, apitos, grandes megafones ou dispositivos artificiais que emitam ruídos, bem como tocar instrumentos musicais ou outros.</li>
                <li>Realizar manifestações diversas.</li>
                <li>Acessar com animais, exceto cães guia ou cães de apoio emocional mediante documentação comprobatória.</li>
                <li>Utilizar os banheiros do parque para banho.</li>
                <li>Acessar usando capacetes ou coberturas que ocultem a face - LEI 6717/14.</li>
                <li>Comercializar qualquer tipo de produto ou serviço sem prévia autorização da empresa.</li>
                <li>Realizar qualquer apologia a crime.</li>
                <li>Acessar o Parque com garrafas, copos ou recipientes de vidro, contendo bebidas alcoólicas ou não alcoólicas.</li>
            </ul>
        </>
    )
}

export default AcessRulesPT