function ExperienceRulesEN() {
    return (
        <ul className="mt-4">
            <li><p>The purchase of this experience must be made at least 3 days in advance.</p></li>
            <li><p>Event subject to cancellation due to weather conditions.</p></li>
            <li><p>Cancellation can be made up to the date and time of the experience. If cancellation occurs less than 48 hours before the experience, there will be a retention of 20% of the amount paid.</p></li>
            <li><p>No refunds will be given for cancellation requests made (i) after the date and time of the Boarding or Event; or (ii) after the tour has taken place.</p></li>
            <li><p>Cancellation requests for online purchases must be made through Customer Service by emailing <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>.</p></li>
            <li><p>It is possible to reschedule the experience up to 7 days after the original date.</p></li>
            <li><p>In the event of abandonment or withdrawal from the experience after it has begun, there will be no refund or compensation for unused services, as the operators and guides will have already prepared all the contracted infrastructure.</p></li>
            <li><p>If the user and other people who have purchased Sunrise at Parque Bondinho, do not arrive at the specified time and place for the start of the experience (5:30 AM), without prior communication, it will be considered a "No-Show," and no refunds will be given for the amounts paid.</p></li>
            <li><p>There is no age restriction for this tour.</p></li>
            <li><p>Children under 3 years old do not pay for the experience.</p></li>
            <li><p>The breakfast provided during Sunrise at Parque Bondinho ends at 8:30 AM, but visitors can remain at the Clássico Sunset Club Restaurant to consume from the traditional menu (not included in the package) and also at Parque Bondinho for free visitation.</p></li>
        </ul>
    )
}

export default ExperienceRulesEN;