import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import Image01PT from '../../../assets/img/park/Mini banner PORT.webp';
import Image01EN from '../../../assets/img/park/Mini banner EN.webp';
import Image01ES from '../../../assets/img/park/Mini banner ES.webp';
import Image02PT from '../../../assets/img/park/Banner Blog II.png';
import Image02EN from '../../../assets/img/park/Banner Blog II - ingles.png';
import Image02ES from '../../../assets/img/park/Banner Blog II - espanhol.png';

function ParkType() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [changeBanner, setChangeBanner] = useState<boolean>();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t] = useTranslation();

    useEffect(() => {
        let date = new Date().toLocaleString("pt-BR");
        let dateNow = date.split(" ")[0].split("/").reverse().join("").replaceAll(",", ""); // Replace adicionado para o Edgee;

        if (`${dateNow}` >= "20221226" && `${dateNow}` <= "20230109") {
            //console.log("Aqqqquuuiii 1")
            setChangeBanner(true)
        } else {
            //console.log("Aqqqquuuiii 2")
            setChangeBanner(false)
        }
    }, [])

    return (
        <>
            <Row >
                <Col md={6} className='pb-4'>
                    <a
                        href={"https://blog.bondinho.com.br/qual-ingresso-comprar-para-visitar-o-parque-bondinho-pao-de-acucar/"}
                        target="_blank"
                        rel="noopener noreferrer">
                        {
                            i18next.language === "pt"
                                ?
                                <img src={`${Image01PT}`} alt="Imagem do bondinho com os dizeres entenda qual bilhete escolher para o Parque Bondinho com o botão saiba mais" style={{ width: "100%" }} />
                                :
                                i18next.language === "en"
                                    ?
                                    <img src={`${Image01EN}`} alt="Imagem do bondinho com os dizeres entenda qual bilhete escolher para o Parque Bondinho com o botão saiba mais" style={{ width: "100%" }} />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <img src={`${Image01ES}`} alt="Imagem do bondinho com os dizeres entenda qual bilhete escolher para o Parque Bondinho com o botão saiba mais" style={{ width: "100%" }} />
                                        :
                                        <img src={`${Image01PT}`} alt="Imagem do bondinho com os dizeres entenda qual bilhete escolher para o Parque Bondinho com o botão saiba mais" style={{ width: "100%" }} />
                        }
                    </a>
                </Col>
                <Col md={6} className="pb-4">
                    <a
                        href={"https://blog.bondinho.com.br/onde-comer-no-parque-bondinho-pao-de-acucar/"}
                        target="_blank"
                        rel="noopener noreferrer">
                        {
                            i18next.language === "pt"
                                ?
                                <img src={`${Image02PT}`} alt="Imagem de um prato de camarões com os dizeres descubra o restaurante perfeito para seu almoço com botão de saiba mais" style={{ width: "100%" }} />
                                :
                                i18next.language === "en"
                                    ?
                                    <img src={`${Image02EN}`} alt="Imagem de um prato de camarões com os dizeres descubra o restaurante perfeito para seu almoço com botão de saiba mais" style={{ width: "100%" }} />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <img src={`${Image02ES}`} alt="Imagem de um prato de camarões com os dizeres descubra o restaurante perfeito para seu almoço com botão de saiba mais" style={{ width: "100%" }} />
                                        :
                                        <img src={`${Image02PT}`} alt="Imagem de um prato de camarões com os dizeres descubra o restaurante perfeito para seu almoço com botão de saiba mais" style={{ width: "100%" }} />
                        }
                    </a>
                </Col>
            </Row>
        </>
    )
}

export default ParkType;