function ExperienceRulesPT() {
    return (
        <ul className="mt-4">
            <li><p>É preciso realizar a compra dessa experiência com pelo menos 3 dias de antecedência.</p></li>
            <li><p>Evento sujeito a cancelamento devido a condições climáticas.</p></li>
            <li><p>O cancelamento poderá ser realizado até a data e hora da experiência. Caso o cancelamento ocorra em prazo inferior a 48 horas antes da experiência, haverá uma retenção de 20% do valor pago.</p></li>
            <li><p>Não serão reembolsados pedidos de cancelamentos efetuados (i) após a data e horário do Embarque ou Evento; ou (ii) após a realização do passeio.</p></li>
            <li><p>Os pedidos de cancelamento para compras no site, deverão ser feitos ao SAC no contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a>.</p></li>
            <li><p>Há possibilidade de reagendamento da experiência até 7 dias após a data original.</p></li>
            <li><p>Na hipótese de abandono ou desistência da experiência, após iniciado o seu curso, igualmente não haverá qualquer direito de reembolso ou compensação pelos serviços não utilizados, pois os operadores e guias já terão preparado toda a infraestrutura contratada.</p></li>
            <li><p>No caso de o usuário e demais pessoas que tenham adquirido o Amanhecer no Parque Bondinho não comparecerem na hora e local marcados para o início da experiência (5h30), sem comunicação prévia, acarretará “No-Show”, ou seja, não serão reembolsados os valores pagos.</p></li>
            <li><p>Não há restrição de idade para este passeio.</p></li>
            <li><p>Crianças menores de 3 anos não pagam pela experiencia.</p></li>
            <li><p>O Café da Manhã disponibilizado no Amanhecer no Parque Bondinho termina às 8h30, mas os visitantes podem permanecer no Restaurante Clássico Sunset Club para consumo do menu tradicional (não incluso no produto) e também no Parque Bondinho para visitação livre.</p></li>
        </ul>
    )
}

export default ExperienceRulesPT;