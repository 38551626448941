/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";

import api from "../../services/api";
import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import iconTimes from "../../assets/icons/svg/fa-times.svg";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  removeItemCart,
  removeTotalCart,
  removeCupomCart,
  updateCart
} from "../../store/cart";

import "./CartHeader.scss";

function CartHeader() {
  const { t } = useTranslation();
  //const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const dispatch = useAppDispatch();

  //const [cartItemJSON, setCartItemJSON] = useState<any>(JSON.parse(cartBPA));

  const [, setResTourCode] = useState<any>(1);

  useEffect(() => {
    const urlCode = window.location.href.split('/code/');

    function verifyTourCode() {
      if (urlCode.length >= 2) {
        localStorage.setItem("tour_code", urlCode[1])
        validateTourCode();
      }
    }

    const validateTourCode = async () => {
      try {
        const { data } = await api.get(`/api/TourCode/ValidateTourCode/${urlCode[1]}`);
        if (data.status !== 400) {
          if (data.data.typeDiscount === 3) {
            verifyAllCart();
          } if (data.data.typeDiscount === 2) {
            cartBPA.discount = data.data.valueDiscount;
            cartBPA.tourCode.code = urlCode[1];
            dispatch(updateCart(cartBPA));
            //localStorage.setItem("cartBPA", JSON.stringify(cartItemJSON));
          }
          if (false) {}
          setResTourCode(data.data);
        }

      } catch (error: any) {
        if (error?.response?.status === 400) {
        }
      }
    }


    const verifyAllCart = () => {
      var dados: any = [...cartBPA.dados];

      cartBPA.TourCode = {
        code: urlCode[1]
      }
      cartBPA.dados.forEach((cItem: any, index: any) => {
        const validateTourCodeProduct = async () => {
          try {
            const { data } = await api.get(`/api/TourCode/ValidateTourCodeProduct/${urlCode[1]}/${cItem.productCode}`);
            if (data.status !== 400) {
              cItem.discountBool = true;
              cItem.discount = data.data.valueDiscount;
            } else {
              cItem.discountBool = false;
              cItem.discount = 0;
            }

            if (index === dados.length - 1) {
              dispatch(updateCart(cartBPA));
            }

          } catch (error: any) {
            if (error?.response?.status === 400) {
            }
          }
        }
        // cItem.discountValue = discountPromise?.valueDiscount;
        validateTourCodeProduct();
      });

      cartBPA.discount = 0;
      dispatch(updateCart(cartBPA));
      //localStorage.setItem("cartBPA", JSON.stringify(cartItemJSON));
    }

    // const validateTourCodeProduct = async (productCode: any) => {
    //   try {
    //     const { data } = await api.get(`/TourCode/ValidateTourCodeProduct/${urlCode[1]}/${productCode}`);
    //     if (data.status !== 400) {
    //       console.log(data.data);
    //     }

    //   } catch (error: any) {
    //     if (error?.response?.status === 400) {
    //       console.log(error.response);
    //     }
    //   }
    // }

    verifyTourCode();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   localStorage.setItem("cartBPA", '{"agent":"CPA3120","Country":"BR","source":"site","resellerReference":"","language":1,"foreign":2,"cupomName":"","dados":[]}');
  // },[lng])



  /* const handleClickRemove = (productE: any) => {
    cartItemJSON.dados.forEach((cItem: any, index: any) => {
      if (productE.productCode === cItem.productCode) {
        cartItemJSON.dados.splice(index, 1);
        
        setCartItemJSON(cartItemJSON);
        localStorage.setItem("cartBPA", JSON.stringify(cartItemJSON));
        if(cartItemJSON.dados.length === 0){
          handleClearCart();
        }
        window.location.reload();
      }
    });
  }; */

  const handleClearCart = () => {
    dispatch(removeTotalCart(cartBPA.dados));
    /* localStorage.removeItem("cartBPA");
    setCartItemJSON("");
    window.location.reload(); */
  };

  let totalCart = 0;
  if (cartBPA.dados.length >= 1) {

    return (
      <li className="nav-item dropdown carrinho nav-item-c2" data-cart="item">
        <a
          className="nav-link text-white text-center"
          data-cart="btn"
          href="#"
          id="navbarDropdownCarrinho"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon
            icon={["fal", "shopping-cart"]}
            size="1x"
            style={{ color: "#ffffff", width: "28px", fontSize: "28px" }}
          />
          <div className="d-inline-block" id="number-circle">
            <span
              id="numeroItens"
              className="badge badge-pill badge-primary text-white"
            >
              {cartBPA.dados.length}
            </span>
          </div>
        </a>
        <div
          className="dropdown-menu px-4 py-2 mb-0"
          aria-labelledby="navbarDropdownCarrinho"
          data-cart="divMenu"
        >
          <h4 className="text-primary py-2 pb-2">{t("cartHeader.title")}</h4>
          <div className="row bg-white p-3 pb-2" id="itemsCarts">
            {cartBPA.dados.length >= 1
              ? cartBPA.dados.map((cItem: any, index: any) => {
                let priceProduct = (
                  (Number(cItem.adults) * Number(cItem.priceAdults))
                  + (Number(cItem.childs) * Number(cItem.priceChilds))
                  + (Number(cItem.infants) * Number(cItem.priceInfants))
                  + (Number(cItem.elders) * Number(cItem.priceElders))
                  + (Number(cItem.student) * Number(cItem.priceStudent))
                  + (Number(cItem.priceGlobalPeople))
                );

                totalCart = Number(totalCart) + (priceProduct - (priceProduct * (cItem.discount / 100)));

                cartBPA.totalCart = totalCart;

                return (
                  <div className="border-bottom row mb-2 py-2 px-0 m-0" key={index}>
                    <div className="col-4 p-0" style={{ position: "relative" }}>
                      <img
                        src={
                          cItem.productType === 1
                            ?
                            cItem.imagesBaseUrl + cItem.imgCart
                            :
                            cItem.imagesBaseUrl === 4
                              ?
                              cItem.productType + cItem.imgCart
                              :
                              cItem.imagesBaseUrl + cItem.imgCart
                        }
                        className="card-img"
                        alt="..."
                      />
                    </div>
                    <div className="col-7 mb-0">
                      <h6 className="text-primary">{cItem.productName}</h6>
                      <div className="row">
                        <div className="col-6">
                          <small>
                            {/* <i className="fal fa-user fa-lg text-primary"></i> */}
                          </small>
                          <small className="pt-0 pb-0 m-0 text-dark pr-2">
                            {(Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student) + Number(cItem.totalPeople | 0))}{" "}
                            {t("cartHeader.numberPeople")}
                          </small>
                        </div>
                        <div className="col-6">
                          <small>
                            {/* <i className="fal fa-calendar fa-lg text-primary"></i> */}
                          </small>
                          <small className="p-0 m-0 text-dark">
                            {" "}
                            {String(cItem.date).split('-').reverse().join('/')}
                          </small>
                        </div>
                      </div>
                      <p className="p-0 m-0">
                        <span className="float-left p-0">
                          R${" "}
                          {(priceProduct - (priceProduct * (cItem.discount / 100))).toFixed(2).split(".").join(",")}
                        </span>
                      </p><br />
                      {cItem.discountBool === true ? <p>Desconto: <small>{cItem.discount}% </small></p> : ''}
                        
                        
                    </div>
                    <div className="col-1 col-md-1 order-1 order-md-2" style={{ position: "relative", right: "10px" }}>
                      <button
                        //onClick={() => handleClickRemove(cItem)}
                        onClick={() => {
                          dispatch(removeItemCart(cItem));
                        }}
                        className="p-0 m-0 text-muted"
                        style={{ cursor: "pointer" }}
                      >
                        <i
                          className="text-muted btn-exclui btn btn-circle bg-light text-dark btn-exclui-carrinho"
                        >
                          <img src={iconTimes} alt="" width={10} />
                        </i>
                      </button>
                    </div>
                  </div>
                );
              })
              : ""}           
          </div>

          {cartBPA.cupom?.type === 2 ?
            <div className="row bg-primary pb-0 mb-0" style={{ color: "#FFF" }}>
              <div style={{ fontSize: "12px" }} className="col-12 pt-3 px-3">
                <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartBPA.cupom.codeName}</span>       <span>R$ {cartBPA.cupom.value.toFixed(2).split(".").join(",")} 
                <button
                onClick={() => {
                  dispatch(removeCupomCart());
                }}
                className="p-0 m-0 text-muted"
                style={{ cursor: "pointer"}}
                >
                  <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                  <FontAwesomeIcon style={{color: "#FFF"}} icon={["fal", "times"]}/>
                </i> 
                </button>
                
                </span> </small>
              </div>
            </div>
            : ''}

              {cartBPA.cupom?.type === 4 ?
          <div className="row bg-primary pb-0 mb-0" style={{ color: "#FFF" }}>
            <div style={{fontSize: "12px"}} className="col-12 pt-3 px-3">             
              
              
                <small style={{ display: "flex", justifyContent: "space-between" }}><span>{cartBPA.cupom.codeName}</span>       <span> {cartBPA?.cupom?.value + '%'}
                <button
                onClick={() => {
                  dispatch(removeCupomCart());
                }}
                className="p-0 m-0 text-muted"
                style={{ cursor: "pointer" }}
                >
                  <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">                    
                    <FontAwesomeIcon style={{color: "#FFF"}} icon={["fal", "times"]}/>
                </i> 
                </button>                
                </span> </small>
              </div>
            </div>    
                : ''}

             
           <div className= {cartBPA.cupom?.type === 2 || cartBPA.cupom?.type === 4 ? 
           "row bg-primary p-3 pt-2 mt-0"
            :
            "row bg-primary p-3  mt-0"} id="row-total" style={{ color: "#FFF" }}>              

            <div className="col-3 p-0" id="div-total">
              {t("cartHeader.total")}:
            </div>
            <div className="col-9 text-right p-0" id="totalTic">
              {cartBPA?.cupom?.type === 2 ?
                <div className="" data-price="total">R$ {(cartBPA.totalCart - cartBPA.discount).toFixed(2).split(".").join(",")}</div>
                : cartBPA?.cupom?.type === 4 ?
                  <div className="" data-price="total">R$ {(totalCart - totalCart * (cartBPA?.cupom?.value / 100)).toFixed(2).split(".").join(",")}</div>
                  : <div className="" data-price="total">R$ {(totalCart).toFixed(2).split(".").join(",")}</div>
              }
            </div>
          </div>

          <div className="dropdown-divider"></div>
          <div className="text-right d-flex mb-1" id="btn-div">
            <div className="w-50 px-1">
              <button
                title=""
                onClick={() => handleClearCart()}
                className="btn btn-outline-primary w-100"
                id="clear-cart"
              >
                {" "}
                {t("cartHeader.clean")}
              </button>
            </div>
            <div className="w-50 px-1">
              <a
                href="/checkout"
                className="btn btn-secondary w-100"
                id="btn-fechar"
              >
                {" "}
                {t("cartHeader.close")}
              </a>
            </div>
          </div>
        </div>
      </li>
    );
  } else {
    return (
      <li className="nav-item dropdown carrinho nav-item-c2" data-cart="item">
        <a
          className="nav-link text-primary text-center"
          data-cart="btn"
          href="#"
          id="navbarDropdownCarrinho"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon
            icon={["fal", "shopping-cart"]}
            size="1x"
            style={{ color: "#ffffff", width: "28px", fontSize: "28px" }}
          />
          <div className="d-inline-block" id="number-circle">
            <span
              id="numeroItens"
              className="badge badge-pill badge-primary text-white"
            >
              0
            </span>
          </div>
        </a>
        <div
          className="dropdown-menu px-4 py-2"
          aria-labelledby="navbarDropdownCarrinho"
          data-cart="divMenu"
        >
          <h4 className="text-primary py-2 pb-2"> {t("cartHeader.title")}</h4>
          <div className="row bg-white p-3 text-muted" id="itemsCarts">
            {t("cartHeader.notItem")}.
          </div>
        </div>
      </li>
    );
  }
}

export default CartHeader;
