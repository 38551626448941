import React, { useState } from "react";

import Modal from 'react-bootstrap/Modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./ModalConfirm.scss";

function ModalConfirm(props: any) {
    const [modalShow, setModalShow] = useState<any>(false);

    function Leave() {
        if (props.confirm !== "Excedido prazo de cancelamento de 7 dias após a compra.") {
            window.location.reload();
        } else { }
    }

    return (
        <>
            <Modal
                {...props}
                size="lg"
                show={modalShow}
                //onHide={() => setModalShow(false)}
                aria-labelledby="contained-modal-title-vcenter"
                className="modal-confirm"
            >
                <Modal.Body className='modal-body text-center sucess-pos'>
                    {
                        props.modalContent !== "loading"
                            ?
                            <>
                                <div className='space-modal'>
                                    {
                                        props.log === 1 || props.log === "1"
                                            ?
                                            <FontAwesomeIcon
                                                icon={["fal", "times-circle"]}
                                                size="5x"
                                                style={{ fontSize: "7.5em", color: "#FF6600" }}
                                            />
                                            :
                                            props.log === 0 || props.log === "0"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "check-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                />
                                                :
                                                <FontAwesomeIcon
                                                    icon={["fal", "question-circle"]}
                                                    size="5x"
                                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                />
                                    }
                                </div>
                                <div className='space-modal'>
                                    
                                    {props.confirm === 'Excedido prazo de cancelamento de 7 dias após a compra.' ? 
                                    
                                    <div className="sucess-pos" id="wrapper" >
                                    <div className="text-center">
                                       
                                        <h4 className='mt-4'>Desculpe, algo deu errado com seu cancelamento</h4>
                                        <p>Se você estiver no prazo de 7 dias a partir da data da compra, conforme politica estabelecida, tente novamente.
                                            <br />
                                            Se persistir, entre em contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a></p> 
                                    </div>
                                    
                                </div>
                                
                            : 
                            <span
                                id="description"
                                dangerouslySetInnerHTML={{
                                __html: props.confirm,
                                }}
                            ></span>
                            // <>{props.confirm}</>
                            
                            }
                                </div>
                                {
                                    props.log === 1 || props.log === "1" || props.log === 0 || props.log === "0"
                                        ?
                                        <div className='space-modal'>
                                            <button className="btn btn-outline-primary mx-2" onClick={() => {
                                                setModalShow(false);
                                                props.setConfirm("Tem certeza que deseja cancelar esse item?");
                                                props.setLog(undefined);
                                                Leave();
                                            }}>
                                                Sair
                                            </button>
                                        </div>
                                        :
                                        <div className='space-modal'>
                                            <button className="btn btn-outline-primary mx-2"
                                                onClick={() => {
                                                    setModalShow(false);
                                                    props.setConfirm("Tem certeza que deseja cancelar esse item?");
                                                    props.setLog(undefined)
                                                }}>
                                                Recusar
                                            </button>
                                            <button
                                                onClick={() => {
                                                    props.cancelReservation(props.reservation);
                                                    props.setConfirm("Tem certeza que deseja cancelar esse item?");
                                                    props.setLog(undefined)
                                                }}
                                                data-btn="cancelItem"
                                                title=""
                                                id="73910"
                                                data-controler="CDN2382"
                                                data-toggle="modal"
                                                className="btn btn-primary mx-2"
                                            >
                                                Confirmar
                                            </button>

                                        </div>
                                }
                            </>
                            :
                            <div className="modal-body">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div>
                                    <span>Processando</span>
                                </div>
                                <div></div>
                            </div>

                    }
                </Modal.Body>
            </Modal>
            <div className="cancel-reservation">
                <span onClick={() => setModalShow(true)}>
                    Cancelar item da reserva
                </span>
            </div>
        </>
    )
}

export default ModalConfirm;