/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import apiGoogleMaps from "../../../services/apiGoogleMaps";
import configData from "../../../config/config.json";

import { Controller, useForm } from "react-hook-form";
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';

import GetCountrys from "../../C2Points/GetCountrys";
import InputMask from "react-input-mask";
import './StepData.scss'

import AuthLogin from "../../Authenticated/AuthLogin/AuthLogin";
import CheckoutSummary from "../CheckoutSummary/CheckoutSummary";

import ModalPrivacy from "../../Modal/ModalPrivacy/ModalPrivacy";
import TermsUse from "../../TermsUse/TermsUse";
import PrivacyTerms from "../../PrivacyTermsNotice/PrivacyTerms";

import i18next from "i18next";
import api from "../../../services/api";
import AcessRules from "../../AccessRules/AccessRules";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addAddressCart, updateDadosCart } from "../../../store/cart";

export interface propAction {
    action: any;
    back: any;
}

const StepData: React.FC<propAction> = ({
    action, back
}: propAction) => {
    const dispatch = useAppDispatch();

    const cartC2: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const [nacionalityBr, setNacionalityBr] = useState<any>("checked");

    const { userModel }: any = JSON.parse(localStorage.getItem('bpasiteAuth') || '{}');
    let auth = localStorage.getItem('bpasiteAuth') || '{}';

    // const cpf = userModel !== undefined ? userModel.cpf : null;
    // const firstName = userModel !== undefined ? userModel.firstName : null;
    // const lastName = userModel !== undefined ? userModel.lastName : null;
    // const cellphone = userModel !== undefined ? userModel.cellphone : null;
    // const email = userModel !== undefined ? userModel.email : null;


    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cpf, setCpf] = useState('');
    const [phone, setPhone] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [email, setEmail] = useState('');
    const [cep, setCEP] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [addressNumber, setAddressNumber] = useState('');
    const [addressComplement, setAddressComplement] = useState('');
    const [country, setCountry] = useState('BR');

    const [modalShow, setModalShow] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [disableCpf, setDisableCpf] = useState(false);
    const [disablePhone, setDisablePhone] = useState(false);
    const [disablePassport, setDisablePassport] = useState(false);
    const [disableCep, setDisableCep] = useState(false);
    const [disableState, setDisableState] = useState(false);
    const [disableCity, setDisableCity] = useState(false);
    const [disableAddress, setDisableAddress] = useState(false);
    const [disableNumber, setDisableNumber] = useState(false);
    const [disableComplement, setDisableComplement] = useState(false);

    const getAuthenticateAccount = async () => {
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}` },
        };


        try {
            const { data } = await api.post('/api/LoginSite/GetPerfil', '', config);
            if (data.status !== 400) {
                setFirstName(data.data.firstName);
                setLastName(data.data.lastName);
                setCpf(data.data.cpf);
                setPhone(data.data.phone);
                setWhatsapp(data.data.whatsapp);
                setEmail(data.data.email);
                setCEP(data.data.userComplement.postCode);
                setState(data.data.userComplement.stateDesc);
                setCity(data.data.userComplement.city);
                setAddress(data.data.userComplement.address);
                setCountry(data.data.userComplement.country === null || data.data.userComplement.country === 'Brasil' ? 'BR' : data.data.userComplement.country);
                setAddressNumber(data.data.userComplement.number);
                setAddressComplement(data.data.userComplement.complement);
                setNacionalityBr(data.data.cpf === null ? false : 'checked');

                /* if (data.data.cpf !== null && data.data.foreign !== 2) {
                    CpfValidator(data.data.cpf);
                } else if (data.data.foreign === 2) {
                    setCpfValidation(true);
                } */

                if (data.data.cpf !== null) {
                    CpfValidator(data.data.cpf);
                } else {
                    setCpfValidation(true);
                }

                setDisableCpf(!(data.data.cpf === null || data.data.cpf === ''));
                setDisablePassport(!(data.data.passport === null || data.data.passport === ''));
                setDisableCep(!(data.data.userComplement.postCode === null || data.data.userComplement.postCode === ''));
                setDisableState(!(data.data.userComplement.state === null || data.data.userComplement.state === ''));
                setDisableCity(!(data.data.userComplement.city === null || data.data.userComplement.city === ''));
                setDisableAddress(!(data.data.userComplement.address === null || data.data.userComplement.address === ''));
                setDisableNumber(!(data.data.userComplement.number === null || data.data.userComplement.number === ''));
                setDisableComplement(!(data.data.userComplement.complement === null || data.data.userComplement.complement === ''));
                setDisablePhone(!(data.data.phone === null || data.data.phone === ''));

                setValue("firstName", data.data.firstName);
                setValue("lastName", data.data.lastName);
                setValue("cpf", data.data.cpf);
                setValue("passport", data.data.passport);
                setValue("tel", data.data.phone);
                setValue("whatsapp", data.data.whatsapp);

                setValue("email", data.data.email);
                setValue("cep", data.data.userComplement.postCode);
                setValue("state", data.data.userComplement.state);
                setValue("city", data.data.userComplement.city);
                setValue("address", data.data.userComplement.address);
                setValue("nacionality", data.data.userComplement.country);
                setValue("number", data.data.userComplement.number);
                setValue("complement", data.data.userComplement.complement);

            } else {

            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
            } else if (error?.response?.status === 401) {
                // window.location.href = window.location.origin + '/login';
                localStorage.removeItem('bpasiteAuth')
            }

        }

    }

    useEffect(() => {
        if (userModel !== undefined) {
            getAuthenticateAccount();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    function ScrollTop() {
        window.scrollTo(0, 0);
    }

    const [cpfValidation, setCpfValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" do cpf
    const [invalidCpf, setInvalidCpf] = useState<boolean>(false);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const { t } = useTranslation();

    function CpfValidator(strCPF: any) {
        if (strCPF !== null) {
            // eslint-disable-next-line no-useless-escape
            strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '').replace(/\_/g, '');  //remove ".", "-" e "-" que a máscara coloca
            var Soma;
            var Resto;
            Soma = 0;

            if (strCPF === "00000000000") {
                setCpfValidation(false)
                return false;
            }

            for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(9, 10))) {
                setCpfValidation(false)
                return false;
            }

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(10, 11))) {
                setCpfValidation(false)
                return false;
            }

            setCpfValidation(true)
            return true;
        } else {
            setCpfValidation(false)
        }
    }

    const onSubmit = (data: any) => {
        data.tel = data.tel.split("_").join("");

        if (verifyInputs()) {
            data.address = address;
            data.city = city;
            data.state = state;
            /* data.country = country; */
            /* let checked: any = document.querySelector('#chackPolice')
            data.checkPolice = checked.checked; */

            if (CpfValidator(data.cpf) || nacionalityBr === false) {
                setInvalidCpf(false);

                if (nacionalityBr === "checked") {
                    data.BR = true;
                    data.EST = false;
                    data.nacionality = null;
                    data.passport = null;
                } else {
                    data.BR = false;
                    data.EST = true;
                    data.cpf = null;
                }

                const passengers = [{
                    "firstName": data.firstName,
                    "lastName": data.lastName,
                    "name": data.firstName + ' ' + data.lastName,
                    "email": data.email,
                    "phone": data.tel,
                    "DocumentNumber": nacionalityBr === "checked" ? data.cpf.split('.').join('').split('-').join('') : data.passport
                }];

                cartC2.dados.forEach((cItem: any, index: any) => {
                    cItem.passengers = passengers;
                    if (cItem.productType === 2) {
                        cItem.cia = data.cia;
                        cItem.voo = data.voo;
                    }
                });

                const address: any = {                
                    country: nacionalityBr === "checked" ? "BR" : country,
                    zipCode: data.cep,
                    state: data.state,
                    city: data.city,
                    address: data.address,
                    number: data.number,
                    complement: data.complement,
                    language: i18next.language === 'pt' ? 1 : i18next.language === 'en' ? 2 : i18next.language === "pt-BR" ? 1 : i18next.language === "es" ? 3 : 'pt',
                    foreign: nacionalityBr === "checked" ? 2 : 1,
                }

                // window.cart.dados.map((cItem: any, index: any) => {
                //     cItem.passengers = passengers;
                // });

                // window.cart.Country = nacionalityBr === "checked" ? "BR" : country;
                // window.cart.State = data.state;
                // window.cart.City = data.city;
                // window.cart.ZipCode = data.cep;
                // window.cart.Street = data.address;
                // window.cart.Complement = data.complement;
                // window.cart.Number = data.number;
                // window.cart.language = i18next.language === 'pt' ? 1 : i18next.language === 'en' ? 2 : i18next.language === "pt-BR" ? 1 : i18next.language === "es" ? 3 : 'pt';
                // window.cart.foreign = nacionalityBr === "checked" ? 2 : 1;

                dispatch(addAddressCart(address));
                dispatch(updateDadosCart(cartC2.dados));
                setCtrlEventKey(true);
                action();
            } else {
                setInvalidCpf(true);
            }
        }
    };

    const showNacionality = () => {
        if (nacionalityBr === false) {
            CpfValidator(getValues().cpf);
            setNacionalityBr("checked");
        } else {
            setCpfValidation(true)
            setNacionalityBr(false);
        }
    };

    const BrChecked = () => {
        return (
            <>
                <Form.Group as={Col} data-nacion="cpf" controlId="formGridPassword">
                    <FloatingLabel controlId="floatingCPF" label="CPF">
                        <Controller
                            control={control}
                            name="cpf"
                            rules={{ required: { value: true, message: 'Por favor, informe o CPF.' } }}
                            render={({ field }: any) => (
                                <InputMask mask="999.999.999-99" disabled={disableCpf} value={field?.value} onChange={field?.onChange} onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()} /* onInput={(e: any) => { CpfValidator(e.target.value) }} */>
                                    {(inputProps: any) => (
                                        <Form.Control
                                            {...inputProps}
                                            aria-invalid={errors?.cpf ? "true" : ""}
                                            label="CPF"
                                            placeholder="CPF"
                                            variant="standard"
                                            margin="normal"
                                            type="text"
                                            disabled={disableCpf}
                                            required
                                        />
                                    )}
                                </InputMask>
                            )}
                        />
                    </FloatingLabel>
                    {invalidCpf === true ? <small style={{ color: "red" }}>Por favor, informe um cpf válido</small> : ''}
                </Form.Group>
            </>
        )
    }

    const EstChecked = () => {
        return (
            <>
                <Form.Group as={Col} md={3} controlId="formGridPassword" className="mt-3 mt-md-0 custom-select" style={{ position: 'relative' }}>
                    <Form.Label
                        style={{ opacity: '0.65', transform: 'scale(0.75) translateY(-2.2rem) translateX(-0.85rem)', position: 'absolute', padding: '0.5rem 0.75rem', top: '-3px', left: '0' }}>
                        {t("checkoutSteps.nacionality")}
                    </Form.Label>
                    {/* <FloatingLabel controlId="floatingPassport" label={t("checkoutSteps.nacionality")}> */}
                    <Controller
                        control={control}
                        name="nacionality"
                        render={({ field }: any) => (
                            <GetCountrys propsField={field} propsLabel={t("checkoutSteps.nacionality")} propsErrors={errors} getTheValue={true} defaultCountry={country} setCountry={setCountry} />
                        )}
                    />
                    {/*  </FloatingLabel> */}
                    {/* <Form.Control
                                {...field}
                                {...register("nacionality")}
                                type="text"
                                placeholder="Nacionalidade"
                                required
                            /> */}

                </Form.Group>
                <Form.Group as={Col} md={3} controlId="formGridPassword" className="mt-3 mt-md-0">
                    <FloatingLabel controlId="floatingPassport" label={t("checkoutSteps.pass")}>
                        <Controller
                            control={control}
                            name="passport"
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    {...register("passport")}
                                    type="text"
                                    placeholder="Passaporte"
                                    disabled={disablePassport}
                                    size={11}
                                    required
                                />
                            )}
                        />
                    </FloatingLabel>
                </Form.Group>
            </>
        )
    }

    const searchCEP = async (term: any) => {
        const response = await apiGoogleMaps.get("/geocode/json", {
            params: {
                address: term,
                key: configData.GOOGLE_KEYS.GOOGLE_MAPS,
            },
        });

        if (response.data.status === "OK") {
            response.data.results[0].address_components.forEach(function (place: any) {
                switch (place.types[0]) {
                    case "route":
                        setAddress(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Address"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Address"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    case "administrative_area_level_2":
                        setCity(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    case "locality":
                        (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    case "administrative_area_level_1":
                        setState(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Estado"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Estado"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    default:
                }
            });
        }
    };

    const handleBlurCEP = (event: any) => {
        searchCEP(event.target.value);
    };

    const verifyInputs = () => {
        const data: any = getValues();

        if ((data.cpf === '' || data.cpf === null) && nacionalityBr === 'checked') {
            setModalShow(true);
            setErrorMessage('CPF');
            return (false);
        } else {
            CpfValidator(data.cpf);
        }

        if ((data.passport === '' || data.passport === null) && nacionalityBr !== 'checked') {
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.pass"));
            return (false);
        }


        if (data.cep === '' || data.cep === null) {
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.cep"));
            return (false);
        }

        if ((data.state === '' || data.state === null) && state === null) {
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.state"));
            return (false);
        }

        if ((data.city === '' || data.city === null) && city === null) {
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.city"));
            return (false);
        }

        if ((data.address === '' || data.address === null) && address === null) {
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.address"));
            return (false);
        }

        if (data.tel === ''){
            setModalShow(true);
            setErrorMessage(t("checkoutSteps.phone"));
            return (false);
        }

        return (true);
    };


    return (
        <Container className="form-checkout" fluid>
            <Row className='d-flex justify-content-center'>
                {auth === "{}" || auth === 'null' ?
                    <div className="step-login my-4 bg-white p-3 mb-2 rounded col-12 col-md-4 col-lg-4 col-xl-4 aside order-2 order-md-1">
                        <h4 className="text-primary">{t("navHeader.login.title")}</h4>
                        <p>{t("navHeader.login.subTitle")}</p>
                        <AuthLogin isCheckoutStep={true} action={getAuthenticateAccount} />
                    </div>
                    :
                    <>
                        <Col md={8} className="my-4 order-1 order-md-2">
                            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                                <div className="card p-2">
                                    <div className="card-header bg-black-10 p-3 text-uppercase">
                                        {t("checkoutSteps.reserveInfo")}
                                    </div>
                                    <div className="my-4">
                                        <Row className="mb-4 d-flex aligm-items-center">
                                            <Form.Group as={Col}>
                                                <Row>
                                                    <Form.Group as={Col} controlId="BR">
                                                        <label htmlFor="radio1">
                                                            <Form.Group>
                                                                <Controller
                                                                    control={control}
                                                                    name="BR"
                                                                    render={({ field }: any) => (
                                                                        <Form.Check
                                                                            {...field}
                                                                            {...register("BR")}
                                                                            type="radio"
                                                                            id="radio1"
                                                                            name="radio"
                                                                            label={t("checkoutSteps.br")}
                                                                            value="1"
                                                                            checked={nacionalityBr === 'checked'}
                                                                            onChange={showNacionality}
                                                                            required
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        </label>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="EST">
                                                        <label htmlFor="radio2">
                                                            <Form.Group>
                                                                <Controller
                                                                    control={control}
                                                                    name="EST"
                                                                    render={({ field }: any) => (
                                                                        <Form.Check
                                                                            {...field}
                                                                            {...register("EST")}
                                                                            type="radio"
                                                                            id="radio2"
                                                                            name="radio"
                                                                            label={t("checkoutSteps.est")}
                                                                            value="2"
                                                                            checked={nacionalityBr === false}
                                                                            onChange={showNacionality}
                                                                            required
                                                                        />
                                                                    )}
                                                                />
                                                            </Form.Group>
                                                        </label>
                                                    </Form.Group>
                                                </Row>
                                            </Form.Group>

                                            {nacionalityBr === "checked" ?
                                                <BrChecked />
                                                :
                                                <EstChecked />
                                            }

                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} className="mb-4" controlId="formGridAddress1">
                                                <FloatingLabel controlId="floatingName" label={t("checkoutSteps.name")}>
                                                    <Controller
                                                        control={control}
                                                        name="firstName"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("firstName")}
                                                                placeholder="Nome"
                                                                disabled={true}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} className="mb-4" controlId="formGridAddress2">
                                                <FloatingLabel controlId="floatingLastname" label={t("checkoutSteps.lastname")}>
                                                    <Controller
                                                        control={control}
                                                        name="lastName"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("lastName")}
                                                                placeholder="Sobrenome"
                                                                disabled={true}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} controlId="formGridAddress1">
                                                <FloatingLabel controlId="floatingTel" label={t("checkoutSteps.phone")}>
                                                    <Controller
                                                        control={control}
                                                        name="tel"
                                                        render={({ field }: any) => (
                                                            <InputMask maskPlaceholder='' mask={`${nacionalityBr === "checked" ? "(99) 9.9999-9999" : "9999999999999999"}`} value={field?.value} onChange={field?.onChange}>
                                                                {(inputProps: any) => (
                                                                    <Form.Control
                                                                        {...inputProps}
                                                                        placeholder="Telefone"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        type="text"
                                                                        onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                        required
                                                                    />
                                                                )}
                                                            </InputMask>

                                                        )}
                                                    />
                                                    {/* {
                                                nacionalityBr === "checked"
                                                    ?
                                                    <Controller
                                                        control={control}
                                                        name="tel"
                                                        render={({ field }: any) => (
                                                            <InputMask mask="(99) 9.9999 -9999" value={field?.value} onChange={field?.onChange} >
                                                                {(inputProps: any) => (
                                                                    <Form.Control
                                                                        {...inputProps}
                                                                        placeholder="Telefone"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                )}
                                                            </InputMask>

                                                        )}
                                                    />
                                                    :
                                                    <Controller
                                                        control={control}
                                                        name="tel"
                                                        render={({ field }: any) => (
                                                            <InputMask mask="+49 99 999 9999999" value={field?.value} onChange={field?.onChange} >
                                                                {(inputProps: any) => (
                                                                    <Form.Control
                                                                        {...inputProps}
                                                                        placeholder="Telefone"
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        type="text"
                                                                        required
                                                                    />
                                                                )}
                                                            </InputMask>

                                                        )}
                                                    />

                                            } */}
                                                </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridAddress2">
                                                <FloatingLabel controlId="floatingEmail" label={t("checkoutSteps.email")}>
                                                    <Controller
                                                        control={control}
                                                        name="email"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("email")}
                                                                type="email"
                                                                placeholder="Email"
                                                                disabled={true}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </div>

                                {/* <div className="card p-2 mt-4">
                            <div className="card-header bg-black-10 p-3 text-uppercase">
                                {t("checkoutSteps.addInfo")}
                            </div>
                            <small className="mt-2">{t("checkoutSteps.dataInfo")}</small>
                            <div className="my-4">
                                <Row>
                                    <Form.Group as={Col}>
                                        <FloatingLabel label={t("checkoutSteps.company")}>
                                            <Controller
                                                control={control}
                                                name="cia"
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        {...register("cia")}
                                                        id="FormControlInput1CIA"
                                                        type="text"
                                                        placeholder={t("checkoutSteps.company")}
                                                    />
                                                )}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <FloatingLabel label={t("checkoutSteps.voo")}>
                                            <Controller
                                                control={control}
                                                name="voo"
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        {...register("voo")}
                                                        id="FormControlInput1Voo"
                                                        type="text"
                                                        placeholder={t("checkoutSteps.voo")}
                                                    />
                                                )}
                                            />
                                        </FloatingLabel>
                                    </Form.Group>
                                </Row>
                            </div>
                        </ div> */}

                                <div className="card p-2 mt-4">
                                    <div className="card-header bg-black-10 p-3 text-uppercase">
                                        {t("checkoutSteps.addressPay")}
                                    </div>
                                    <div className="my-4">
                                        <Row className="mb-4">
                                            <Form.Group as={Col}>
                                                <FloatingLabel controlId="floatingCEP" label={t("checkoutSteps.cep")}>
                                                    <Controller
                                                        control={control}
                                                        name="cep"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("cep")}
                                                                type="text"
                                                                placeholder="CEP"
                                                                onBlur={handleBlurCEP}
                                                                defaultValue={cep !== null ? cep : ""}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} htmlFor="FormControlInput1Estado">
                                                <FloatingLabel label={t("checkoutSteps.state")}>
                                                    <Controller
                                                        control={control}
                                                        name="state"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("state")}
                                                                id="FormControlInput1Estado"
                                                                type="text"
                                                                placeholder="Estado"
                                                                defaultValue={state !== null ? state : ""}
                                                                value={state}
                                                                disabled={disableState}
                                                                onChange={(e: any) => { setState(e.target.value) }}
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Cidade">
                                                <FloatingLabel label={t("checkoutSteps.city")}>
                                                    <Controller
                                                        control={control}
                                                        name="city"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("city")}
                                                                id="FormControlInput1Cidade"
                                                                autoComplete="off"
                                                                placeholder="Cidade"
                                                                defaultValue={city !== null ? city : ""}
                                                                value={city}
                                                                disabled={disableCity}
                                                                onChange={(e: any) => { setCity(e.target.value) }}
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Address">
                                                <FloatingLabel label={t("checkoutSteps.address")}>
                                                    <Controller
                                                        control={control}
                                                        name="address"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("address")}
                                                                id="FormControlInput1Address"
                                                                placeholder="Endereço"
                                                                defaultValue={address !== null ? address : ""}
                                                                value={address}
                                                                disabled={disableAddress}
                                                                onChange={(e: any) => { setAddress(e.target.value) }}
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Row>

                                        <Row>
                                            <Form.Group as={Col} htmlFor="FormControlInput1Number">
                                                <FloatingLabel label={t("checkoutSteps.num")}>
                                                    <Controller
                                                        control={control}
                                                        name="number"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                {...register("number")}
                                                                //disabled={true}
                                                                defaultValue={addressNumber !== null ? addressNumber : ""}
                                                                value={addressNumber}
                                                                disabled={disableNumber}
                                                                id="FormControlInput1Number"
                                                                placeholder="Número"
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="formGridAddress2">
                                                <FloatingLabel controlId="floatingComp" label={t("checkoutSteps.comp")}>
                                                    <Controller
                                                        control={control}
                                                        name="complement"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                //disabled={true}
                                                                {...register("complement")}
                                                                defaultValue={addressComplement !== null ? addressComplement : ""}
                                                                value={addressComplement}
                                                                disabled={disableNumber}
                                                                placeholder="Complemento"
                                                            />
                                                        )}
                                                    />
                                                </FloatingLabel>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                </div>

                                <Row className="my-4">
                                    <Form.Group as={Col} controlId="BR" className="d-flex mx-4">
                                        <ModalPrivacy
                                            type="privacy"
                                            name={t("checkoutSteps.access")}
                                            header={t("checkoutSteps.access")}
                                            classBody="mb-5"
                                        >
                                            <AcessRules />
                                        </ModalPrivacy>
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Col md={6} className="col-4">
                                        <Button
                                            title=""
                                            onClick={back}
                                            className="btn btn-primary bg-previous text-uppercase btn-back-dados w-100 p-3"
                                            data-toggle="pill"
                                            data-button="step2"
                                        >
                                            {t("checkoutSteps.back")}
                                        </Button>
                                    </Col>
                                    <Col md={6} className="col-8">
                                        <Button
                                            className="btn btn-primary  text-uppercase mt-0 w-100 p-3 border-0"
                                            data-toggle="pill"
                                            data-btn="step2"
                                            type="submit"
                                            onClick={ScrollTop}
                                        >
                                            {t("checkoutSteps.continue")}
                                        </Button>
                                    </Col>
                                </Row>

                            </Form>

                        </Col >

                        <CheckoutSummary></CheckoutSummary>
                        <Modal
                            className="modal-custom modalCheckout modalPayment"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            backdrop="static"
                        >
                            <>
                                <Modal.Body>
                                    <div className="sucess-pos" id="wrapper" >
                                        <div className="text-center mb-5">
                                            <FontAwesomeIcon
                                                icon={["fal", "times-circle"]}
                                                size="5x"
                                                className='color-danger'
                                            />
                                        </div>
                                        <div className='d-flex align-items-center flex-column my-3'>
                                            <p className="m-0">{`${t('checkoutSteps.alertInvalidField')}:`}</p>
                                            <p>{`${errorMessage}`}</p>
                                        </div>
                                        <div className='modal-btn-controller'>
                                            <Button className="my-5 mx-auto" onClick={() => { setModalShow(false) }}>
                                                {t("modal.creditCard.success.closeBtn")}
                                            </Button>
                                        </div>
                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>
                    </>
                }
            </Row >
        </Container >
    );
}

export default StepData;
