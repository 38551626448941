import React, { useState } from 'react';
import api from "../../services/api";
import { Accordion } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { updateCart, removeCupomCart } from "../../store/cart";
import SimpleAlert from '../Alert/SimpleAlert/SimpleAlert';

function DiscountCoupon() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState<any>(false);
  const [modalMessage, setModalMessage] = useState<any>('Carregando');
  const [modalState, setModalState] = useState<any>();

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
  const dispatch = useAppDispatch();

  //const [resTourCode, setResTourCode] = useState<any>(1);

  /*  const validateTourCode = async (code: any) => {
 
     try {
       const { data } = await api.get(`/api/TourCode/ValidateCoupon/${code}`);
       if (data.status !== 400) {
         if (data.data.typeDiscount === 3) {
 
         } else if (data.data.typeDiscount === 4) {
           cartBPA.cupom = {
             type: data.data.typeDiscount,
             Code: data.data.codeName,
             value: data.data.valueDiscount,
           };
           dispatch(updateCart(cartBPA));
           window.location.reload();
         }
         //setResTourCode(data.data);
       }
 
     } catch (error: any) {
       if (error?.response?.status === 400) {
       }
     }
   } */

  const validateTourCode = async (code: any) => {
    setModalState('loading');
    setShowModal(true);

    if (code === '') {
      setModalState('error');
      setModalMessage("Cupom inválido");
    }
    try {
      const { data } = await api.get(`/api/TourCode/ValidateCoupon/${code}`);
      if (data.status !== 400) {
        if (data.data === null) {
          setModalState('error');
          setModalMessage("Cupom inválido");
        } else {
          if (data.data.typeDiscount !== 3) {
            setModalState('success');
            setModalMessage("Cupom adicionado com sucesso");
          }

          var cupom: any;
          if (data.data.typeDiscount === 2) {
            cartBPA.cupom = {
              type: data.data.typeDiscount,
              code: data.data.code,
              codeName: data.data.codeName,
              value: data.data.valueDiscount,
            };
            cartBPA.discount = data.data.valueDiscount;
            cartBPA.tourCode = undefined

            dispatch(updateCart(cartBPA));

          }
          else if (data.data.typeDiscount === 3) {
            cartBPA.cupom = {
              type: data.data.typeDiscount,
              code: data.data.code,
              codeName: data.data.codeName,
              value: data.data.valueDiscount,
            };
            cartBPA.discount = data.data.valueDiscount;
            cartBPA.tourCode = undefined;

            verifyAllCart();
          } else if (data.data.typeDiscount === 4) {
            cartBPA.cupom = {
              type: data.data.typeDiscount,
              code: data.data.code,
              codeName: data.data.codeName,
              value: data.data.valueDiscount,
            };
            cartBPA.discount = data.data.valueDiscount;
            cartBPA.tourCode = undefined;

            dispatch(updateCart(cartBPA));
            //dispatch(updateCupomCart(cupom));

          }
        }
      }

    } catch (error: any) {
      if (error?.response?.status === 400) {
      }
    }
  }

  const verifyAllCart = () => {
    var dados: any = [...cartBPA.dados]
    let atLeastOneValid: boolean = false;
    cartBPA.dados.forEach((cItem: any, index: any) => {
      const validateTourCodeProduct = async () => {
        try {
          const { data } = await api.get(`/api/TourCode/ValidateTourCodeProduct/${cartBPA.cupom.code}/${cItem.productCode}`);
          if (data.statusCode === 200) {
            atLeastOneValid = true;
            cItem.discountBool = true;
            cItem.discount = data.data.valueDiscount;
          } else {
            cItem.discountBool = false;
            cItem.discount = 0;
          }

          if (index === dados.length - 1) {
            dispatch(updateCart(cartBPA));

            if (atLeastOneValid === true) {
              setModalState('success');
              setModalMessage("Cupom adicionado com sucesso");
            } else {
              setModalState('success');
              setModalMessage("Cupom não elegível para essa compra");
            }
          }

        } catch (error: any) {}
      }

      validateTourCodeProduct();

    });

  }


  function onSubmit(e: any) {
    e.preventDefault();

    validateTourCode(e.target.cupom.value)
  }


  return (
    <div className="card-cupom">
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0" className='checkout-shadow'>
          <div className="p-2">
            <Accordion.Header>{t("checkoutSteps.titleCupom")}</Accordion.Header>
          </div>
          <Accordion.Body>
            <form onSubmit={onSubmit}>
              <input type="text" name="cupom" placeholder={t("checkoutSteps.placeholderCupom")} className='checkout-cupoms' />
              <button type='submit' className="btn text-white checkout-cta">{t("checkoutSteps.apply")}</button>

              {/*  */}
              {/* {cartBPA.tourCode === undefined
                ?
                <>
                  <input type="text" name="cupom" placeholder={t("checkoutSteps.placeholderCupom")} className='checkout-cupoms' />
                  {modalState === 'success' || modalState === 'error' ? <div className='w-100'><SimpleAlert alertType={modalState}>{modalMessage}</SimpleAlert></div> : <></>}
                  <div className="row w-100 d-flex justify-content-between">

                    <div className="col-6 p-0">
                      <button type='button' className="btn btn-outline-primary btn-block text-primary btn-activities checkout-remove-cupom w-100" onClick={() => { dispatch(removeCupomCart()); }}>{t("checkoutSteps.removeCupom")}</button>
                    </div>
                    <div className="col-6">
                      <button type='submit' className="btn text-white checkout-cta mx-2 w-100 h-100">{modalState === 'loading' ? <div className='load small white'></div> : t("checkoutSteps.apply")}</button>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="col-6 p-0">
                    <button type='button' className="btn btn-outline-primary btn-block text-primary btn-activities checkout-remove-cupom w-100" onClick={() => { dispatch(removeCupomCart()); }}>{t("checkoutSteps.removeTourCode")}</button>
                  </div>
                  <div className="col-6">

                  </div>
                </>
              } */}
              {/*  */}

            </form>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default DiscountCoupon;