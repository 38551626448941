import React from "react";

function AcessRulesES() {
    return (
        <>
            {/* <p>Planee su visita con seguridad y sin imprevistos</p> */}

            <h6 className="my-4"><b>No es permitido:</b></h6>

            <ul className="list-acess-rules">
                <li>Filmar o fotografiar con fines comerciales, sin autorización previa de la empresa.</li>
                <li>Portabilidad o uso de Drones.</li>
                <li>Acceder con materiales voluminosos (p. ej. trípode, instrumentos musicales, sombrilla, silla de playa, hielera/poliestireno, disfraces voluminosos, maletas o equipos de más de 25 x 35 x 55 cm).</li>
                <li>Acceder o permanecer sin camisa, en traje de baño o descalzo.</li>
                <li>Comer o beber dentro de los tranvías o llevar comida sin envoltorio sellado.</li>
                <li>Acceder con monopatín, patinete, patines o zapatillas con ruedas incorporadas, bicicleta o similar.</li>
                <li>Acceso con balón o globo.</li>
                <li>Los niños o adolescentes menores de 16 (dieciséis) años acceden o permanecen en el parque sin la compañía de sus padres o tutores legales. Los responsables de menores deberán presentar documentación que acredite la relación de parentesco hasta en tercer grado (padres, madres, hermanos, tíos, sobrinos, abuelos), o autorización de los padres, en los términos previstos en la ley.</li>
                <li>Plantar o eliminar especies de flora.</li>
                <li>Practicar cualquier tipo de depredación del medio ambiente.</li>
                <li>Alimentar, acercarse o cazar a los animales.</li>
                <li>Portar armas de fuego, municiones, dispositivos de defensa personal (spray de pimienta, pistolas paralizantes), objetos cortantes o puntiagudos.</li>
                <li>Acceso con objetos o líquidos inflamables o explosivos.</li>
                <li>Sonido fuerte, usando bocinas, silbatos, megáfonos grandes o dispositivos artificiales que emitan ruido, así como tocar instrumentos musicales u otros.</li>
                <li>Realizar diversas manifestaciones.</li>
                <li>Acceso con animales, excepto perros guía o perros de apoyo emocional con documentación acreditativa.</li>
                <li>Use los baños del parque para bañarse.</li>
                <li>Acceder con cascos o cubiertas que oculten el rostro - LEI 6717/14.</li>
                <li>Comercializar cualquier tipo de producto o servicio sin autorización previa de la empresa.</li>
                <li>Realizar cualquier apología del delito.</li>
                <li>Acceder al Parque conbotellas, vasos o recipientes de vidrio, que contengan bebidas alcohólicas o noalcohólicas.</li>
            </ul>
        </>
    )
}

export default AcessRulesES;