import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface propTicket {
  ticket: any,
};

const CheckoutSummaryTicket: React.FC<propTicket> = ({
  ticket
}: propTicket) => {
  const [language, setLanguage] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(i18next.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language])

  return (
    <div>
      <div className="row border-bottom">
        <div className="col-12 text-left mt-4">
          <h6 className="m-0 p-0 text-uppercase">{language === "pt" ? ticket.productNameBR : language === "en" ? ticket.productNameEN : language === "es" ? ticket.productNameES : ticket.productNameBR}</h6>
          <small>{t("checkoutSteps.date")}: {ticket.date.split("-").reverse().join("/")} {ticket?.hours?.length !== 1 ? ticket?.time?.split(":")[0] + ":" + ticket?.time?.split(":")[1] : ""}{" "}
          </small>
        </div>
      </div>
      {
        ticket.ranges.labelsRealSorted.map((faixa: any, i: any) => {
          return (
            <div key={i}>
              {
                (ticket[`${faixa.toLowerCase()}`] | ticket[`${faixa.toLowerCase()}s`]) > 0
                  ?
                  faixa !== "Private"
                    ?
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{ticket.ranges[`label${faixa}`]}: </div>
                      <div className="col-2 text-center px-0">{(ticket[`${faixa.toLowerCase()}`] | ticket[`${faixa.toLowerCase()}s`])} x</div>
                      <div className="col-5 text-right ">R$ {(Number(ticket[`price${faixa}`]) | Number(ticket[`price${faixa}s`])).toFixed(2).split('.').join(',')}</div>
                    </div>
                    :
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{ticket.ranges[`labelPrivate`]}: </div>
                      <div className="col-2 text-center px-0">{ticket[`totalPeople`]} x</div>
                      <div className="col-5 text-right ">R$ {Number(ticket[`priceGlobalPeople`]).toFixed(2).split('.').join(',')}</div>
                    </div>
                  :
                  ""
              }
            </div>
          )
        })}

      {ticket.discountBool === true
        ?
        <div className="row border-bottom py-2 small align-items-center">
          <div className="col-5 text-left">{t("checkoutCards.cardTicket.discount")}: </div>
          <div className="col-2 text-center px-0"> x</div>
          <div className="col-5 text-right ">{Number(ticket.discountValue)}%</div>
        </div>
        :
        ''
      }
    </div>
  );
}

export default CheckoutSummaryTicket;