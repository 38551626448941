/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect, Key } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addItemCart } from "../../../store/cart";

import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";

import ModalDefault from "../../Modal/ModalDefault/ModalDefault";
import ReceiptAlert from "../../Alert/ReceiptAlert/ReceiptAlert";

import SimpleAlert from "../../Alert/SimpleAlert/SimpleAlert";

export interface props {
    tourResponse: any;
    lng: any;
}

const SlowBuyTicketPromotion: React.FC<props> = ({
    tourResponse, lng
}: props) => {
    const { t } = useTranslation();

    const [numberAdults, setNumberAdults] = useState<number>(0);
    const [numberChilds, setNumberChilds] = useState<number>(0);
    const [numberInfants, setNumberInfants] = useState<number>(0);
    const [numberStudent, setNumberStudent] = useState<number>(0);
    const [numberElders, setNumberElders] = useState<number>(0);
    const [numberTotal, setNumberTotal] = useState<number>(1);
    const [dateTour, setDateTour] = useState<any>([]);
    const [price, setPrice] = useState<any>([]);
    const [numberPeople, setNumberPeople] = useState<any>(null);
    const [showDropdown, setShowDropdown] = useState<any>(false);
    const [showCalendar, setShowCalendar] = useState<any>(false);
    const [modalityNumber, setModalityNumber] = useState<any>(0);
    const [alert, setAlert] = useState<any>(false);
    const [alertMessage, setAlertMessage] = useState<any>("");

    const [lang, setLang] = useState<any>();

    const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const dispatch = useAppDispatch();

    const maxNumPeople = 6;

    useEffect(() => {
        if (lng === "BR") {
            setLang(tourResponse.slugBR);
        } else if (lng === "EN") {
            setLang(tourResponse.slugEN);
        } else {
            setLang(tourResponse.slugES);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourResponse])

    const handleClickAdults = (controll: string) => {
        let newNumber = numberAdults;
        if (controll === "sub") {
            if (numberAdults === 0) {
                return false;
            } else {
                newNumber = numberAdults - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberAdults + 1;
            } else {
                return false;
            }
        }
        return setNumberAdults(newNumber);
    };

/*     const handleClickChilds = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberChilds === 0) {
                return false;
            } else {
                newNumber = numberChilds - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberChilds + 1;
            } else {
                return false;
            }
        }
        return setNumberChilds(newNumber);
    };

    const handleClickInfants = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberInfants === 0) {
                return false;
            } else {
                newNumber = numberInfants - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberInfants + 1;
            } else {
                return false;
            }
        }
        return setNumberInfants(newNumber);
    };

    const handleClickStudent = (controll: string) => {
        let newNumber = numberStudent;
        if (controll === "sub") {
            if (numberStudent === 0) {
                return false;
            } else {
                newNumber = numberStudent - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberStudent + 1;
            } else {
                return false;
            }
        }
        return setNumberStudent(newNumber);
    };

    const handleClickElders = (controll: string) => {
        let newNumber = numberElders;
        if (controll === "sub") {
            if (numberElders === 0) {
                return false;
            } else {
                newNumber = numberElders - 1;
            }
        }
        if (controll === "plus") {
            if (numberTotal < maxNumPeople) {
                newNumber = numberElders + 1;
            } else {
                return false;
            }
        }
        return setNumberElders(newNumber);
    };

    function changePriceDateTour(obj: any) {
        setShowDropdown(true)
        setPrice(obj)
        setDateTour(obj);
        setNumberPeople({
            adults: '1',
            childs: '0',
            infants: '0',
            elders: '0',
            student: '0',
            globalPeople: '0'
        });
    } */

    useEffect(() => {
        var aux: any = [];
        for (var i = 0; i < tourResponse.modalities.length; i++) {
            aux.push({
                adult: 0,
                child: 0,
                infant: 0,
            })
        }
        setDateTour(aux);
    }, [tourResponse.modalities])

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants + numberElders + numberStudent);
    }, [numberAdults, numberChilds, numberInfants, numberElders, numberStudent]);

    /* const url = window.location.href;
    const codeUrl = url.split("/");
    const slugUrl = codeUrl[codeUrl.length - 1]; */

    const addToCart = (itemOption: any, productCode: any) => {
        var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

        cartBPA.dados.forEach((elem: any) => {
            if (elem.productCode === productCode) {
                repeatedItem = true
            }
        });

        if (repeatedItem === true) {
            setAlertMessage(t("tour.alertRepeatedItem"));
            setAlert(true);
        } else {
            const item = {
                productName: tourResponse.productName,
                productNameBR: tourResponse.productNameBR,
                productNameEN: tourResponse.productNameEN,
                productNameES: tourResponse.productNameES,
                imgCart: tourResponse.images[0],
                price: itemOption?.tarif?.price,

                idTarif: itemOption.idTarif,
                idPickup: itemOption.idPickup,

                priceAdults: itemOption.priceAdults,
                priceChilds: itemOption.priceChilds,
                priceInfants: itemOption.priceInfants,
                priceElders: itemOption.priceElders,
                priceStudent: itemOption.priceStudent,
                priceGlobalPeople: itemOption.priceGlobalPeople,


                sellingType: tourResponse.idSellingType,
                adults: itemOption.adults,
                childs: itemOption.childs,
                infants: itemOption.infants,
                elders: itemOption.elders,
                student: itemOption.student,
                globalPeople: itemOption.globalPeople,
                productType: 4,
                productCode: tourResponse.productCode,
                /* productSlug: slugUrl, */
                slugBR: tourResponse.slugBR,
                slugEN: tourResponse.slugEN,
                slugES: tourResponse.slugES,
                time: itemOption.time,
                date: itemOption.date,
                supplier: tourResponse.supplier,
                typePickup: itemOption.embarkingType,
                meetingPoint: itemOption.embarkingType === "0" ? itemOption.meetingPoint : '',
                pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
                pickup: 0,

                discount: 0,
                customValueNet: 0,
                customValueSell: 0,
                goingSource: "null",
                commingSource: "null",
                latOrigem: "null",
                lngOrigem: "null",
                latDestino: "null",
                lngDestino: "null",
                cia: "null",
                voo: "null",
                smallSuitcase: 0,
                bigSuitcase: 0,
                internalNotes: " ",
                idVeiculo: 0,
                passengers: []

            }

            //SetCartItem(item);
            dispatch(addItemCart(item));
            window.location.href = window.location.origin + '/checkout';
        }
    }

    const auth = (option: any) => {

        if (numberPeople === null || dateTour.length < 1) {
            setAlertMessage(t("tour.alertFillAllData"));
            setAlert(true);
        } else {
            option.adults = numberAdults;
            option.childs = numberChilds;
            option.infants = numberInfants;
            option.elders = numberElders;
            option.student = numberStudent;
            option.globalPeople = 0;


            /* option.date = (document.getElementById(`date-hidden`) as HTMLInputElement).value.split('/').reverse().join('-'); */
            option.date = (document.getElementById(`date-${option.id}`) as HTMLInputElement).value.split('/').reverse().join('-');
            option.time = "19:00:00";

            /* const selectedDate = JSON.parse((document.getElementById(`date-hidden`) as HTMLInputElement).dataset.object || '{}'); */
            const selectedDate = JSON.parse((document.getElementById(`date-${option.id}`) as HTMLInputElement).dataset.object || '{}');

            option.idTarif = selectedDate.idTarif;
            /* option.idPickup = (document.getElementById(`pickup`) as HTMLInputElement).value; */
            option.idPickup = "";


            option.priceAdults = selectedDate.priceAdultFinal;
            option.priceChilds = selectedDate.priceChildFinal;
            option.priceInfants = selectedDate.priceInfantFinal;
            option.priceElders = selectedDate.priceEldersFinal;
            option.priceStudent = selectedDate.priceStudentFinal;
            option.priceGlobalPeople = selectedDate.priceGlobalFinal;

            if (numberAdults + numberChilds + numberInfants + numberElders + numberStudent > 0) {
                addToCart(option, tourResponse.productCode);
            } else {
                setAlertMessage(t("tour.alertSelectAPerson"));
                setAlert(true);
                /* window.alert("Selecione uma pessoa") */
            }
        }
    }

    /* const auth = (option: any) => {
        if (numberPeople === null || dateTour.length < 1) {
            alert('Preencha todos os dados');
        } else {
            const selectedDate = JSON.parse((document.getElementById(`date-${option.id}`) as HTMLInputElement).dataset.object || '{}');

            const item = {
                productName: tourResponse.productName,
                imgCart: tourResponse.images[0],
                price: tourResponse.modalities[0]?.tarif?.price,

                idTarif: selectedDate.idTarif,
                idPickup: "",

                priceAdults: dateTour[0].adult,
                priceChilds: dateTour[0].child,
                priceInfants: dateTour[0].infant,
                priceElders: 0,
                priceStudent: 0,
                priceGlobalPeople: dateTour[0].global,


                sellingType: numberPeople.idSellingType,
                adults: Number(numberPeople.adults),
                childs: Number(numberPeople.childs),
                infants: Number(numberPeople.infants),
                elders: Number(numberPeople.elders),
                student: Number(numberPeople.student),
                globalPeople: Number(numberPeople.globalPeople),
                productType: 4,
                productCode: tourResponse.productCode,
                productSlug: slugUrl,
                time: tourResponse.modalities[0].activityStart,
                date: (document.getElementById(`date-${option.id}`) as HTMLInputElement).value.split('/').reverse().join('-'),
                supplier: tourResponse.supplier,
                typePickup: tourResponse.modalities[0].embarkingType,
                meetingPoint: tourResponse.modalities[0].embarkingType === "0" ? tourResponse.modalities[0].meetingPoint : '',
                pickupListId: tourResponse.modalities[0].embarkingType === "0" ? 0 : tourResponse.modalities[0].pickupListId,
                pickup: 0,

                discount: 0,
                customValueNet: 0,
                customValueSell: 0,
                goingSource: "null",
                commingSource: "null",
                latOrigem: "null",
                lngOrigem: "null",
                latDestino: "null",
                lngDestino: "null",
                cia: "null",
                voo: "null",
                smallSuitcase: 0,
                bigSuitcase: 0,
                internalNotes: " ",
                idVeiculo: 0,
                passengers: []

            }
            SetCartItem(item);
        }
    } */

    const handleChangeModalities = (value: any) => {
        setShowCalendar(false);
        setShowDropdown(false);
        setModalityNumber(value);
        setShowCalendar(true);
    }

    return (
        <>
            <div className="container fast-buy-modal my-4">
                <h2 className="fast-buy-title">{tourResponse.productName}</h2>

                <div className="row fast-buy-content">
                    <div className="col-12 col-md-5 fast-buy-description">
                        <span
                            id="description"
                            dangerouslySetInnerHTML={{
                                __html: tourResponse.productDescription,
                            }}
                        ></span>
                        <a href={
                            tourResponse.typeProduct === "TOUR"
                                ?
                                `/tour/${lang}`
                                :
                                `/ticket/${lang}`
                        } className='mt-3' style={{ color: "#000" }}>
                            {t("slowBuy.ticket.moreDetails")}
                        </a>
                    </div>
                    <div className="col-12 col-md-7 fast-buy-people">
                        <h4 className="text-primary">{t("slowBuy.ticket.title")}</h4>
                        <div>
                            <div className="">
                                <div className="panel-dropdown-content right active">
                                    <div className="select-date">
                                        <div className="mb-5">
                                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo01")}</strong> {t("slowBuy.ticket.select01")}</p>
                                            <select onChange={(e: any) => { handleChangeModalities(e.target.value) }} className="form-control rounded-0 bg-white py-2 rounded modality-input" name="modalities" id="modalities">
                                                <option value='' disabled selected>{t("slowBuy.ticket.select01")}</option>
                                                {tourResponse.modalities.length > 0
                                                    ? tourResponse.modalities.map((item: any, index: Key) => {
                                                        return (
                                                            <>
                                                                <option value={index}>{item.modalityName}</option>
                                                            </>
                                                        );

                                                    })
                                                    : ""
                                                }
                                            </select>
                                        </div>
                                        <div className="mb-5">
                                            {showCalendar === true
                                                ?
                                                <>
                                                    <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo02")}</strong> {t("slowBuy.ticket.select02")}</p>
                                                    {/* <DoubleCalendarTicket
                                                        modalityID={tourResponse.modalities[modalityNumber].id}
                                                        product={tourResponse.modalities[modalityNumber]}
                                                        productCode={tourResponse.productCode}
                                                        changePriceDateTour={changePriceDateTour}
                                                        dateTour={dateTour}
                                                        index={0}
                                                    ></DoubleCalendarTicket> */}
                                                </>
                                                : ''
                                            }
                                        </div>
                                        {showDropdown === true
                                            ?
                                            <>

                                                <p style={{ fontSize: "18px", color: "#707070", marginTop: '3rem' }}><strong>{t("slowBuy.ticket.passo03")}</strong> {t("slowBuy.ticket.select03")}</p>

                                                <div className="prices-modal px-1 px-md-4">
                                                    <div className="qtyButtons">
                                                        <div className="flex-ajuste">
                                                            <label>
                                                                {t("slowBuy.ticket.int")}
                                                            </label>
                                                            {/* <small id="idade-adulto">(11-100)</small> */}
                                                        </div>
                                                        <div>
                                                            <div className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                                                <span>-</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="qtyInput"
                                                                value={numberAdults}
                                                                id="numero-adulto"
                                                            />
                                                            <div className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                                                <span>+</span>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-adulto-sm"
                                                            >
                                                                R$ {(price[0].adult).toFixed(2).split(".").join(",")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <div className="qtyButtons">
                                                        <div className="flex-ajuste">
                                                            <label>
                                                                {t("slowBuy.ticket.child")}
                                                            </label>
                                                            <small id="idade-crianca">({t("slowBuy.ticket.years01")})</small>
                                                        </div>
                                                        <div>
                                                            <div className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                                                <span>-</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="qtyInput"
                                                                value={numberChilds}
                                                                id="numero-crianca"
                                                            />
                                                            <div className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                                                <span>+</span>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-crianca-sm"
                                                            >
                                                                {" "}
                                                                R$ {(price[0].child).toFixed(2).split(".").join(",")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="qtyButtons">
                                                        <div className="flex-ajuste">
                                                            <label>
                                                                {t("slowBuy.ticket.teen")}
                                                            </label>
                                                            <small id="idade-crianca">({t("slowBuy.ticket.years02")})</small>

                                                        </div>
                                                        <div>

                                                            <div className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                                                <span>-</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="qtyInput"
                                                                value={numberInfants}
                                                                id="numero-infantil"
                                                            />
                                                            <div className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                                                <span>+</span>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-infat-sm"
                                                            >
                                                                {" "}
                                                                R$ {(price[0].infant).toFixed(2).split(".").join(",")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="qtyButtons">
                                                        <div className="flex-ajuste">
                                                            <label>
                                                                {t("slowBuy.ticket.elders")}
                                                            </label>
                                                            <small id="idade-crianca">({t("slowBuy.ticket.years03")})</small>

                                                        </div>
                                                        <div>

                                                            <div className="qtyDec" onClick={() => handleClickElders("sub")}>
                                                                <span>-</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="qtyInput"
                                                                value={numberElders}
                                                                id="numero-infantil"
                                                            />
                                                            <div className="qtyInc" onClick={() => handleClickElders("plus")}>
                                                                <span>+</span>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-infat-sm"
                                                            >
                                                                {" "}
                                                                R$ {(price[0].elders).toFixed(2).split(".").join(",")}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="qtyButtons">
                                                        <div className="flex-ajuste">
                                                            <label>
                                                                {t("slowBuy.ticket.other")}
                                                            </label>
                                                            <small id="idade-crianca"></small>

                                                        </div>
                                                        <div>

                                                            <div className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                                                <span>-</span>
                                                            </div>
                                                            <input
                                                                type="text"
                                                                name="qtyInput"
                                                                value={numberStudent}
                                                                id="numero-infantil"
                                                            />
                                                            <div className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                                                <span>+</span>
                                                            </div>
                                                        </div>
                                                        <div className="price">
                                                            <span
                                                                className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                                                id="valor-infat-sm"
                                                            >
                                                                {" "}
                                                                R$ {(price[0].student).toFixed(2).split(".").join(",")}
                                                            </span>
                                                        </div>
                                                    </div> */}
                                                    {numberTotal >= 10
                                                        ?
                                                        <SimpleAlert alertType="danger">{t("slowBuy.ticket.limit")}</SimpleAlert>
                                                        :
                                                        ''
                                                    }
                                                    <hr />
                                                    <div className="subtotal flex-ajuste">
                                                        <h5>{t("slowBuy.ticket.sub")}</h5>
                                                        <span
                                                            className="price-dropdown text-dark font-weight-bold d-block"
                                                            id="valor-subtotal-sm"
                                                        >
                                                            {" "}
                                                            R$ {((price[0].adult * numberAdults) + (price[0].child * numberChilds) + (price[0].infant * numberInfants) + (price[0].elders * numberElders) + (price[0].student * numberStudent)).toFixed(2).split(".").join(",")}
                                                        </span>
                                                    </div>
                                                </div>
                                                {/* <div>
                                                    <small>
                                                        {t("slowBuy.ticket.otherText")}
                                                    </small>
                                                </div> */}
                                                <div className="warning">
                                                    <p>{t("slowBuy.ticket.alert")}</p>
                                                </div>

                                            </>
                                            : ''}
                                    </div>
                                </div>

                            </div>

                            {/* <DoubleCalendar modalityID={tourResponse.modalities[0].id} product={tourResponse.modalities[0]} productCode={tourResponse.productCode} /> */}

                        </div>
                        <div className="buy-button">
                            {
                                numberPeople === null
                                    ?
                                    <button
                                        title=""
                                        className="btn btn-primary btn-block btn-add-cart link-add"
                                        id=""
                                        onClick={() => auth(tourResponse.modalities[modalityNumber])}
                                    >
                                        {t("productOptions.buy")}
                                    </button>
                                    :
                                    numberChilds > 0 || numberInfants > 0 || numberElders > 0 || numberStudent > 0
                                        ?
                                        <ModalDefault
                                            class="btn-add-cart btn-slow-buy modal-confirm modal-small"
                                            name={`${t("productOptions.buy")}`}
                                        >
                                            <div className="receipt-alert modal-body">
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={["fal", "info-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em", color: "#FF6600" }}
                                                    />
                                                </div>
                                                <p>Atenção! Necessário a apresentação de comprovante da meia entrada.</p>
                                                <div>
                                                    <button className="btn-add-cart-modal" style={{ width: "auto", fontSize: "1rem", borderRadius: ".65rem" }} onClick={() => auth(tourResponse.modalities[modalityNumber])}>Eu concordo</button>
                                                </div>
                                            </div>
                                        </ModalDefault>
                                        :
                                        tourResponse.productCode === process.env.REACT_APP_MARAVILHA
                                            ?
                                            <ModalDefault
                                                class="btn-add-cart modal-confirm modal-small"
                                                name={`${t("productOptions.buy")}`}
                                            >
                                                <ReceiptAlert auth={auth} option={tourResponse.modalities[modalityNumber]} />
                                            </ModalDefault>
                                            :
                                            <button
                                                title=""
                                                className="btn btn-primary btn-block btn-add-cart link-add"
                                                id=""
                                                onClick={() => auth(tourResponse.modalities[modalityNumber])}
                                            >
                                                {t("productOptions.buy")}
                                            </button>

                            }
                        </div>
                    </div >
                    <Modal
                        className="modal-confirm"
                        show={alert}
                        onHide={() => {
                            setAlert(false)
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <div className='modal-body text-center'>
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    style={{ fontSize: "7.5em", color: "#FF6600" }}
                                />

                            </div>
                            <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                {alertMessage}
                            </div>
                            <div>
                                <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlert(false)}>Fechar</Button>
                            </div>
                        </div>
                    </Modal>

                </div >
            </div >
        </>
    );
}

export default SlowBuyTicketPromotion;