import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import './MenuSideBar.scss';

function MenuSideBar(props: any) {
    const [t] = useTranslation();

    useEffect(() => {
        if (props.open === true) {
            document.getElementById("menuSideBar")!.style.left = "0px";
            document.getElementById("shadow")!.style.display = "block";

        } else {

            document.getElementById("menuSideBar")!.style.left = "-350px";
            document.getElementById("shadow")!.style.display = "none";

        }
    }, [props.open])

    /* Detecta clique fora da div#wrapper para fechar o menuSideBar */
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    props.setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar o menuSideBar\\ */

    return (
        <>
            <div id="menuSideBar" className="hidden">
                <div data-v-403394da="" className="fixed" /* style="z-index: 3003;" */><div>
                    <div data-v-403394da="">
                        <div id="wrapper" ref={wrapperRef} className="custom-scroll x-offset-canvas-menu relative bg-white z-3003 bottom-0 menu-drop" /* style="width: 350px;" */>
                            {/* <div className="p-4 pt-0 justify-between border-b hidden">
                                <div className="w-full flex container">
                                    <div className="flex border rounded-full h-10 w-10 items-center justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 15.258 16.692" stroke="#333a41" fill="#333A41">
                                            <path d="M7.63.25a4.6 4.6 0 00-2.022 8.726 7.39 7.39 0 00-5.358 7.1.368.368 0 10.736 0 6.638 6.638 0 016.366-6.64c.093.006.187.009.281.009s.188 0 .281-.009a6.636 6.636 0 016.361 6.638.368.368 0 10.736 0 7.389 7.389 0 00-5.356-7.1A4.6 4.6 0 007.633.248zm0 .736A3.862 3.862 0 017.903 8.7c-.091 0-.182-.005-.273-.005s-.183 0-.273.005A3.862 3.862 0 017.63.986z" stroke-width=".5" fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div className="flex flex-col mx-auto justify-center">
                                        <p className="uppercase text-20">
                                            <strong>!</strong>
                                        </p>
                                        <p className="text-13 text-white-lavender"></p>
                                    </div>
                                    <div>
                                    </div>
                                </div>
                            </div> */}
                            <button id="menu-close-button" className="x-close-button absolute" onClick={() => { props.setOpen(false) }} aria-label="Fechar">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 20.252 20.252" fill="#333A41">
                                    <path d="M11.769 10.126l8.142 8.142a1.162 1.162 0 11-1.643 1.644l-8.143-8.143-8.142 8.143A1.162 1.162 0 01.34 18.268l8.142-8.142L.34 1.984A1.162 1.162 0 011.983.34l8.142 8.143 1.643 1.643zm8.142-8.142s-4.642 4.666-6.5 6.5a1.162 1.162 0 11-1.644-1.644c2.027-2.035 6.5-6.5 6.5-6.5a1.162 1.162 0 011.643 1.643z"></path>
                                </svg>
                            </button>
                            <div className="menu-options-container">
                                <div className="menu-options">
                                    <ul>
                                        <li className="mb-3">
                                            <a href="/" className="">
                                                <span>{t("navHeader.sideBar.home")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/parque" className="">
                                                <span>{t("navHeader.sideBar.thePark")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/lojas" className="">
                                                <span>{t("navHeader.sideBar.stores")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href={"https://blog.bondinho.com.br/experiencias-exclusivas-de-tirar-o-folego-e-no-parque-bondinho-pao-de-acucar/"} target="_blank" className="" rel="noreferrer">
                                                <span>{t("navHeader.sideBar.exclusiveCableCar")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href={"https://blog.bondinho.com.br/categoria/sustentabilidade/"} target="_blank" className="" rel="noreferrer">
                                                <span>{t("navHeader.sideBar.sustainability")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/nossa-historia" className="">
                                                <span>{t("navHeader.sideBar.ourStory")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href={"https://blog.bondinho.com.br/um-parque-de-possibilidades/"} target="_blank" className="" rel="noreferrer">
                                                <span>{t("footer.menu.events")}</span>
                                            </a>
                                        </li>

                                        <li className="mb-3">
                                            <a href={"https://blog.bondinho.com.br/"} target="_blank" className="" rel="noreferrer">
                                                <span>{t("footer.menu.blog")}</span>
                                            </a>
                                        </li>

                                        <li className="mb-3">
                                            <a href="/como-chegar" className="">
                                                <span>{t("navHeader.sideBar.howtoget")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/regras-de-acesso" className="">
                                                <span>{t("navHeader.sideBar.acessRules")}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://tirolesa.bondinho.com.br/portal/"} target="_blank" className="text-decoration-none" rel="noreferrer">
                                                <span>{t("navHeader.sideBar.zipline")}</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href={"https://sac.bondinho.com.br/hc/pt-br"} target="_blank" className="text-decoration-none" rel="noreferrer">
                                                <span>{t("navHeader.sideBar.talkWithUs")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/trabalhe-conosco" className="">
                                                <span>{t("navHeader.sideBar.workUs")}</span>
                                            </a>
                                        </li>
                                        <li className="mb-3">
                                            <a href="/relacoes-com-investidores" className="">
                                                <span>{t("navHeader.sideBar.relationshipInvestors")}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div id="shadow"></div>
                    </div>
                </div>
                </div>
            </div>
        </>
    );
}

export default MenuSideBar;