/* eslint-disable array-callback-return */
import { createSlice, configureStore } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'counter',
  initialState: {
    cart: localStorage.getItem('cartBPA') === null ? {
      Country: "BR",
      agent: "CPA3120",
      discount: "0",
      foreign: 2,
      language: 1,
      resellerReference: "",
      source: "site",
      totalCart: "0",
      dados: [],
      payments: [],
      cupom: {
        type: 0
      },
      isProductC2Rio: false,
      isProductBdB: false,
      isProductBdBTrilha: false
    }
      :
      JSON.parse(localStorage.getItem('cartBPA') || '{}'),
    koin: {
      sessionId: ' '
    }
  },
  reducers: {

    addItemCart: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.cart.dados = [...state.cart.dados, action.payload]
      if (state.cart.cupom?.type === 3) {
        state.cart.cupom = {
          type: 0
        }
      };

      if(action.payload.reservationSystem === 99) {
        state.cart.isProductC2Rio = true;
      } else {
        if(action.payload.channel === 7) {
          state.cart.isProductBdBTrilha = true;
          state.cart.isProductBdB = true;
        } else {
          state.cart.isProductBdB = true;
        }
      }

      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    addAddressCart: (state: any, action) => {
      state.cart.Country = action.payload.country
      state.cart.city = action.payload.city
      state.cart.state = action.payload.state
      state.cart.street = action.payload.address //A pedido do BACKEND (06/10/2022)
      state.cart.number = action.payload.number
      state.cart.complement = action.payload.complement
      state.cart.zipCode = action.payload.zipCode
      state.cart.foreign = action.payload.foreign
      saveToLocalStorage(state.cart);
    },

    addPaymentCart: (state: any, action) => {
      state.cart.payments = action.payload
      saveToLocalStorage(state.cart);
    },

    updateDadosCart: (state: any, action) => {
      state.cart.dados = [...action.payload]
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateCart: (state: any, action) => {
      state.cart = { ...action.payload };
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeItemCart: (state: any, action) => {
      //console.log(state, action)
      state.cart.dados = state.cart.dados.filter((item: any) => item.productCode !== action.payload.productCode);

      // eslint-disable-next-line array-callback-return
      if(state.cart.dados.length > 0 ) {
        state.cart.dados.map((item: any) => {
          if(item.reservationSystem === 99 && item.reservationSystem !== 0) {
            state.cart.isProductC2Rio = true;
          }
  
          if(item.reservationSystem === 0 && item.reservationSystem !== 99) {
            if(item.channel === 7) {
              state.cart.isProductBdBTrilha = true;
            } else {
              state.cart.isProductBdB = true;
            }
          }
        })
      } else {
        state.cart.isProductBdB = false;
        state.cart.isProductC2Rio = false;
        state.cart.isProductBdBTrilha = false;
      }

      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },


    removeTotalCart: (state: any, action) => {
      //console.log(state, action);
      state.cart.dados = [];
      state.cart.payments = [];
      state.cart.cupom = {
        type: 0
      };
      state.cart.street = undefined;
      state.cart.city = undefined;
      state.cart.state = undefined;
      state.cart.discount = '0';
      state.cart.tourCode = undefined;
      state.cart.agent = 'CPA3120';
      const tempTotalCart = "0";
      state.cart.totalCart = tempTotalCart;
      state.cart.isProductBdB = false;
      state.cart.isProductC2Rio = false;
      state.cart.isProductBdBTrilha = false;

      saveToLocalStorage(state.cart);
    },

    updateCupomCart: (state: any, action) => {
      // state.cart-= 1
      state.cart.cupom = action.payload;
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    removeCupomCart: (state: any) => {
      // state.cart-= 1
      state.cart.cupom = {
        type: 0
      };
      state.cart.discount = '0';
      state.cart.tourCode = undefined;
      const tempTotalCart = updateTotalCart(state.cart);
      state.cart.totalCart = tempTotalCart;
      saveToLocalStorage(state.cart);
    },

    updateKoin: (state: any, action) => {
      state.koin.sessionId = action.payload
    }
  }
})

export const { addItemCart, addAddressCart, addPaymentCart, removeItemCart, updateDadosCart, removeTotalCart, updateCupomCart, removeCupomCart, updateCart, updateKoin } = counterSlice.actions

const store = configureStore({
  reducer: counterSlice.reducer
})

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("cartBPA", serialisedState);

  } catch (e) {
    console.warn(e);
  }
}

function updateTotalCart(state: any) {
  let totalCart = 0;

  try {
    let cartTemp = JSON.stringify(state);
    let cartObj = JSON.parse(cartTemp);
    let priceProduct = 0;
    // eslint-disable-next-line array-callback-return
    cartObj.dados.map((cItem: any, index: any) => {

      if (cItem.sellingType === 1 && cItem.productType !== 4) {
        priceProduct = (
          (Number(cItem.priceGlobalPeople)/*  * Number(cItem.globalPeople) */)
        );
      } else {
        priceProduct = (
          (Number(cItem.adults) * Number(cItem.priceAdults))
          + (Number(cItem.childs) * Number(cItem.priceChilds))
          + (Number(cItem.infants) * Number(cItem.priceInfants))
          + (Number(cItem.elders) * Number(cItem.priceElders))
          + (Number(cItem.student) * Number(cItem.priceStudent))
          + (Number(cItem.globalPeople) * Number(cItem.priceGlobalPeople))
        );
      }
      if (cItem.discountBool === true && cartObj.cupom.type === 3) {
        priceProduct = priceProduct - ((priceProduct * (cItem.discount / 100)));
      }
      totalCart = Number(totalCart) + (priceProduct);


    });
    if (cartObj.cupom.type === 2) {
      totalCart = Number(totalCart) - (cartObj.cupom.value);
    } else if (cartObj.cupom.type === 4) {
      totalCart = Number(totalCart) - ((totalCart * (cartObj.cupom.value / 100)));
    }

    return totalCart;


  } catch (e) {
    console.warn(e);
  }

}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;