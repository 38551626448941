import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PageDafault from "../../templates/PageDafault";
import Slider from "react-slick";

import pdfCodigoEtica01 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0001.jpg';
import pdfCodigoEtica02 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0002.jpg';
import pdfCodigoEtica03 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0003.jpg';
import pdfCodigoEtica04 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0004.jpg';
import pdfCodigoEtica05 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0005.jpg';
import pdfCodigoEtica06 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0006.jpg';
import pdfCodigoEtica07 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0007.jpg';
import pdfCodigoEtica08 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0008.jpg';
import pdfCodigoEtica09 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0009.jpg';
import pdfCodigoEtica10 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0010.jpg';
import pdfCodigoEtica11 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0011.jpg';
import pdfCodigoEtica12 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0012.jpg';
import pdfCodigoEtica13 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0013.jpg';
import pdfCodigoEtica14 from '../../assets/img/pdfCodigoEtica/Codigo-de-Conduta_page-0014.jpg';

import pdfAnti01 from '../../assets/img/pdfAnticorrupção/Anti_0001.jpg';
import pdfAnti02 from '../../assets/img/pdfAnticorrupção/Anti_0002.jpg';
import pdfAnti03 from '../../assets/img/pdfAnticorrupção/Anti_0003.jpg';
import pdfAnti04 from '../../assets/img/pdfAnticorrupção/Anti_0004.jpg';
import pdfAnti05 from '../../assets/img/pdfAnticorrupção/Anti_0005.jpg';
import pdfAnti06 from '../../assets/img/pdfAnticorrupção/Anti_0006.jpg';

import pdfAntiCo01 from '../../assets/img/pdfAnticorrupção/Anti_01.jpg';
import pdfAntiCo02 from '../../assets/img/pdfAnticorrupção/Anti_02.jpg';
import pdfAntiCo03 from '../../assets/img/pdfAnticorrupção/Anti_03.jpg';
import pdfAntiCo04 from '../../assets/img/pdfAnticorrupção/Anti_04.jpg';
import pdfAntiCo05 from '../../assets/img/pdfAnticorrupção/Anti_05.jpg';

import './Compliance.scss';
import { Helmet } from "react-helmet";
import i18next from "i18next";
import { Button, Col, Row } from "react-bootstrap";

function Compliance() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation();
    const [choose, setChoose] = useState<number>(1);

    const settings: any = {
        draggable: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        initialSlide: 0,
        cssEase: "ease-out",
        lazyLoad: 'ondemand', // Lazy load images
    };

    const settingsAnti = {
        ...settings,
    };

    const settingsAnti2 = {
        ...settings,
    };

    return (
        <>
            <Helmet>
                <html lang={i18next.language} />
                <title>Compliance | Parque Bondinho </title>
                <meta name="description" content="Para prevenir, detectar e tratar desvios ou algum tipo de inconformidade, temos o departamento de Compliance no Parque do Bondinho. Acesse e confira!" />
                <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

                {/* Essential META Tags */}
                <meta property="og:title" content="Compliance | Parque Bondinho " />
                <meta property="og:type" content="TouristAttraction" />
                <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
                <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

                {/* Non-Essential, But Recommended */}
                <meta property="og:description" content="Para prevenir, detectar e tratar desvios ou algum tipo de inconformidade, temos o departamento de Compliance no Parque do Bondinho. Acesse e confira!" />
                <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

                <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
                <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
            </Helmet>

            <PageDafault>
                <div className="container py-5 compliance">
                    <h1 className="mb-5 text-center display-2">Compliance</h1>
                    <div className="card py-4 px-3">
                        <p>
                            {t("compliance.text01")}
                        </p>
                        <p>
                            {t("compliance.text02")}
                        </p>

                        <Row className="py-4 text-center justify-content-center">
                            <Col md={4} lg={4} className="mb-3 mb-md-0">
                                <Button variant={choose === 1 ? "primary" : "outline-primary"} className="btn-custom" onClick={() => setChoose(1)}>
                                    {t("compliance.button01")}
                                </Button>
                            </Col>
                            <Col md={4} lg={4} className="mb-3 mb-md-0">
                                <Button variant={choose === 2 ? "primary" : "outline-primary"} className="btn-custom" onClick={() => setChoose(2)}>
                                    {t("compliance.button02")}
                                </Button>
                            </Col>
                            <Col md={4} lg={4}>
                                <Button variant={choose === 3 ? "primary" : "outline-primary"} className="btn-custom" onClick={() => setChoose(3)}>
                                    {t("compliance.button03")}
                                </Button>
                            </Col>
                        </Row>

                        {
                            choose === 1
                                ?
                                <div className="w-100 m-auto my-2 bg-compliance">
                                    <Slider {...settings}>
                                        <img src={pdfCodigoEtica01} alt="Página 01 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica02} alt="Página 02 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica03} alt="Página 03 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica04} alt="Página 04 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica05} alt="Página 05 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica06} alt="Página 06 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica07} alt="Página 07 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica08} alt="Página 08 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica09} alt="Página 09 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica10} alt="Página 10 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica11} alt="Página 11 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica12} alt="Página 12 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica13} alt="Página 13 - Código de Conduta e Ética" width='90%' />
                                        <img src={pdfCodigoEtica14} alt="Página 14 - Código de Conduta e Ética" width='90%' />
                                    </ Slider>
                                </div>
                                :
                                choose === 2
                                    ?
                                    <div className="w-100 m-auto my-2 bg-compliance">
                                        <Slider {...settingsAnti}>
                                            <img src={pdfAntiCo01} alt="Página 01 - Política de Doações e Patrocínios" width='90%' />
                                            <img src={pdfAntiCo02} alt="Página 02 - Política de Doações e Patrocínios" width='90%' />
                                            <img src={pdfAntiCo03} alt="Página 03 - Política de Doações e Patrocínios" width='90%' />
                                            <img src={pdfAntiCo04} alt="Página 04 - Política de Doações e Patrocínios" width='90%' />
                                            <img src={pdfAntiCo05} alt="Página 05 - Política de Doações e Patrocínios" width='90%' />
                                        </ Slider>
                                    </div>
                                    :
                                    <div className="w-100 m-auto my-2 bg-compliance">
                                        <Slider {...settingsAnti2}>
                                            <img src={pdfAnti01} alt="Página 01 - Política Anticorrupção" width='90%' />
                                            <img src={pdfAnti02} alt="Página 02 - Política Anticorrupção" width='90%' />
                                            <img src={pdfAnti03} alt="Página 03 - Política Anticorrupção" width='90%' />
                                            <img src={pdfAnti04} alt="Página 04 - Política Anticorrupção" width='90%' />
                                            <img src={pdfAnti05} alt="Página 05 - Política Anticorrupção" width='90%' />
                                            <img src={pdfAnti06} alt="Página 06 - Política Anticorrupção" width='90%' />
                                        </ Slider>
                                    </div>
                        }
                    </div>
                </div>
            </PageDafault>
        </>
    )
}

export default Compliance;