import React, { useEffect, useState } from "react";
import DatePicker from "react-multi-date-picker";
import i18next from "i18next";

import { useTranslation } from "react-i18next";

import api from "../../services/api";
import configData from "../../config/config.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-multi-date-picker/styles/layouts/mobile.css";
import "./Calendar.scss";


const weekDays = i18next.language === "en" ? ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"] : i18next.language === "es" ? ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"] : ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"];

const months = i18next.language === "en" ? ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"] : i18next.language === "es" ? ["Enero", "Febrero", "Marzo", "April", "Mayo", "Junio", "July", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"] : ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];


const numberMonths = window.innerWidth <= 1360 ? 1 : 2; //480

export interface productInfo {
    modalityID: any;
    product: any;
    productCode: any;
    changePriceDateTour: any;
    index: any;
    dateTour: any;
    setRanges?: any;
    setIsIntegration?: any;
    setApiError: any;
}

let auxRanges: any = [];

const CustomMultipleInput = ({ openCalendar, value, modalityID, valueCalendar, setNewGetDate, loading = false }: any) => {
    const { t } = useTranslation();
    const [arrow, setArrow] = useState<any>(false);

    useEffect(() => {
        let arrowBody = document.querySelector('body');
        if (arrowBody?.className === "modal-open") {
            setArrow(true);
        } else {
            setArrow(false);
        }
    }, []);

    function setTranslate() {
        document.querySelectorAll('.rmdp-week-day').forEach((item) => {
            item.setAttribute("translate", "no");
        })
    }

    function getArrowLimit() {
        var today: any = new Date();
        var monthControl = (today.getMonth() + 1);
        //var actual: any = monthControl;

        let timer1 = setTimeout(() => {

            var teste: any = document.getElementsByClassName('rmdp-arrow-container');


            if (teste.length >= 1) {

                teste[0].addEventListener("click", (e: any) => {
                    monthControl = monthControl - 1;
                    setNewGetDate(monthControl);
                }, false)

                teste[1].addEventListener("click", (e: any) => {
                    monthControl = monthControl + 1;
                    setNewGetDate(monthControl);

                }, false)
            }

        }, 500);

        return () => {
            clearTimeout(timer1);
        };
    }

    return (
        <div className="d-flex">
            {loading ?
                <div className="" style={{ position: "absolute", transform: "translate(5px, 7px)" }}>
                    <div className="load small"></div>
                </div>
                :
                <>
                    <FontAwesomeIcon
                        icon={["fal", "calendar-alt"]}
                        size="1x"
                        style={{ color: "#000", position: "absolute", transform: "translate(10px, 10px)" }}
                    />
                </>
            }
            <input
                className={`form-control rounded-0 bg-white py-2 rounded calendar-input`}
                onFocus={() => { openCalendar(); getArrowLimit(); setTranslate(); }}
                value={value}
                data-object={JSON.stringify(valueCalendar)}
                readOnly
                id={`date-${modalityID}`}
                placeholder={t("productOptions.when")}
            />
            {
                arrow === true
                    ?
                    <FontAwesomeIcon icon={["fal", "chevron-down"]} size="1x" style={{ marginRight: "10px", color: "#707070" }} />
                    :
                    ""
            }
        </div>
    );
};

const DoubleCalendar: React.FC<productInfo> = ({
    modalityID,
    product,
    productCode,
    changePriceDateTour,
    dateTour,
    index,
    setRanges,
    setIsIntegration,
    setApiError
}: productInfo) => {
    const [t] = useTranslation();

    const closedDate = new Date("2024-09-22");

    const url = window.location.href;
    const codeUrl = url.split("/");
    const isTrilha = codeUrl[3] === "compra-rapida" ? codeUrl[4].split("?")[2] : codeUrl[4].split("?")[1];

    const [dayProduct, setDayProduct] = React.useState<any>({});
    const [valueCalendar, setValueCalendar] = useState<any>({});
    const [newGetDate, setNewGetDate] = React.useState<any>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const [auxTarif, setAuxTarif] = useState<any>(null);

    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    const [showMessage, setShowMessage] = useState(false);

    const handleMouseEnter = () => {
        console.log('teste')

        setShowMessage(true);
    };

    const handleMouseLeave = () => {
        setShowMessage(false);
    };


    function handleDateTour(objCalendar: any) {
        let auxTar: any = [];

        for (let i = 0; i < auxTarif.length; i++) {
            if (auxTarif[i].selectedDate.split("T")[0] === objCalendar.data) {
                auxTar.push(auxTarif[i])
            }
        }

        var aux: any = dateTour;
        aux[index].data = objCalendar.data;
        aux[index].idSellingType = Number(objCalendar.idSellingType);
        aux[index].daysUniqueTarCode = objCalendar.daysUniqueTarCode;
        aux[index].prodModUniqueCode = objCalendar.prodModUniqueCode;
        aux[index].tarUniqueCode = objCalendar.tarUniqueCode;
        aux[index].adultText = objCalendar.adultText;
        aux[index].childText = objCalendar.childText;
        aux[index].infantText = objCalendar.infantText;
        aux[index].eldersText = objCalendar.eldersText;
        aux[index].studentText = objCalendar.studentText;
        aux[index].globalText = objCalendar.globalText;

        aux[index].auxTarif = auxTar;

        changePriceDateTour(aux);
    }

    async function getDays(idChannel: number = 2, info: any) {
        let aux: any = [];
        setLoading(true);

        var today: any;
        var todayDay: any;
        var todayMonth: any;
        var todayYear: any;
        var endMonth: any;
        var endYear: any;

        if (newGetDate === null) {
            today = new Date();
            todayDay = today.getDate() < 28 ? today.getDate() : 28;
            todayMonth = (today.getMonth() + 1);
            todayYear = today.getFullYear();
            endMonth = (today.getMonth() + 7);
            endYear = today.getFullYear();

            if (endMonth > 11) {
                endMonth = endMonth - 11;
                endYear = parseInt(endYear) + 1;
            }
        } else {
            today = new Date();
            todayDay = today.getDate() < 28 ? today.getDate() : 28;
            todayMonth = newGetDate - 1;
            todayYear = today.getFullYear();
            endMonth = newGetDate + 3;
            endYear = today.getFullYear();

            if (todayMonth > 12) {
                todayMonth = todayMonth - 12;
                todayYear = parseInt(todayYear) + 1;
            }
            if (endMonth > 12) {
                endMonth = endMonth - 12;
                endYear = parseInt(endYear) + 1;
            }
        }


        try {
            const res: any = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetCalendarTourAsync`,
                {
                    dateStart: todayYear + '-' + todayMonth + '-' + todayDay,
                    dateEnd: endYear + '-' + endMonth + '-' + todayDay,
                    productCode: `${productCode}`,
                    tarCode: `${info.tarCode}`,
                    ProdModCode: `${product.prodModCode}`,
                    lang: lng,
                    idCanal: isTrilha === "trilha" ? 7 : idChannel
                }
            );
            setLoading(false);
            if (res.status === 200) {
                if (res.data.statusCode === 200) {
                    setIsIntegration(res.data?.data?.isIntegrationSystem);

                    if (window.location.pathname.split("/")[1] === "compra-rapida") {
                        setRanges(res.data?.data?.ranges);
                    } else {
                        auxRanges.push({ ...res.data?.data?.ranges, prodModCode: product.prodModCode });
                    }

                    aux.push(...res.data.data.dates);

                    res.data?.data?.dates?.forEach((dayProduct: any) => {
                        let year = dayProduct.selectedDate.split("-")[0];
                        let month = dayProduct.selectedDate.split("-")[1];
                        let day = dayProduct.selectedDate.split("-")[2];

                        let controlObject: { [x: number]: { price: any; data: any, idTarif: any, priceAdultFinal: any, priceChildFinal: any, priceGlobalFinal: any, priceInfantFinal: any, idSellingType: any, daysUniqueTarCode: any, prodModUniqueCode: any, tarUniqueCode: any, adultText: string, childText: string, infantText: string, eldersText: string, studentText: string, globalText: string } };
                        let sControl: any = year + "-" + month + "-" + day.split("T")[0];
                        controlObject = {
                            [sControl]: {
                                price:
                                    dayProduct.priceAdultFinal === 0.0
                                        ? dayProduct.priceGlobalFinal
                                        : dayProduct.priceAdultFinal,
                                idTarif: dayProduct.idTarifario,
                                priceAdultFinal: dayProduct.priceAdultFinal,
                                priceChildFinal: dayProduct.priceChildFinal,
                                priceInfantFinal: dayProduct.priceInfantFinal,
                                priceGlobalFinal: dayProduct.priceGlobalFinal,
                                idSellingType: dayProduct.idSellingType,
                                daysUniqueTarCode: dayProduct.daysUniqueTarCode,
                                prodModUniqueCode: dayProduct.prodModUniqueCode,
                                tarUniqueCode: dayProduct.tarUniqueCode,
                                data: year + "-" + month + "-" + day.split("T")[0],
                                adultText: res.data?.data?.tariffs[0][`tariffAgeAdultText${lng !== "BR" ? lng : ''}`],
                                childText: res.data?.data?.tariffs[0][`tariffAgeChildText${lng !== "BR" ? lng : ''}`],
                                infantText: res.data?.data?.tariffs[0][`tariffAgeInfantText${lng !== "BR" ? lng : ''}`],
                                eldersText: res.data?.data?.tariffs[0][`tariffAgeElderText${lng !== "BR" ? lng : ''}`],
                                studentText: res.data?.data?.tariffs[0][`tariffAgeStudentText${lng !== "BR" ? lng : ''}`],
                                globalText: res.data?.data?.tariffs[0][`tariffAgeGlobalText${lng !== "BR" ? lng : ''}`],
                            },
                        };
                        setDayProduct((curObjDeal: any) => ({
                            ...curObjDeal,
                            ...controlObject,
                        }));

                        setAuxTarif(aux);

                        if (window.location.pathname.split("/")[1] !== "compra-rapida") {
                            setRanges(auxRanges);
                        }
                    });

                    if (res.data?.data?.dates?.length === 0) {
                        //ERRO MODALIDADE SEM DIA DISPONÍVEL
                        //setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.notFoundDates'), errorOnCalendar: true }]) });
                    }
                } else {
                    if (res.data.statusCode !== 204) {
                        //ERRO STATUS CODE 400
                        setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: true }]) });
                    }
                }
            } else {
                //ERRO STATUS 400
                setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: true }]) });
            }
        } catch (error: any) {
            setLoading(false);
            setApiError((prev: any) => { return ([...prev, { index, text: t('modalApiError.errorAPI'), errorOnCalendar: true }]) });
        }
    }

    async function getIdChannel(prod: any) {
        setLoading(true);

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
            if (data.statusCode === 200) {
                getDays(data.data.data, prod);
            } else {
                getDays(2, prod);
            }
        } catch (erro: any) {
            getDays(2, prod);
        }
    }

    useEffect(() => {
        for (let i = 0; i < product.allTarif.length; i++) {
            getIdChannel(product.allTarif[i]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product.id, productCode, newGetDate, index]);

    if (dayProduct !== null) {
        /* funcao(); */
        return (
            <DatePicker
                weekDays={weekDays}
                numberOfMonths={numberMonths}
                disableMonthPicker
                disableYearPicker
                months={months}
                format={configData.DATE.PT}
                render={<CustomMultipleInput modalityID={modalityID} valueCalendar={valueCalendar} setNewGetDate={setNewGetDate} loading={loading} />}
                className={window.innerWidth <= 768 ? "rmdp-mobile multi-locale-days notranslate" : "multi-locale-days notranslate"}
                mapDays={({ date }): any => {
                    const isDisabledDate = (
                        date.day === closedDate.getDate() &&
                        date.month.index === closedDate.getMonth() &&
                        date.year === closedDate.getFullYear()
                    );

                    if (isDisabledDate) {
                        return {
                            disabled: true,
                            children: (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        fontSize: "14px",
                                        color: "grey",
                                    }}
                                >
                                    {date.day}
                                </div>
                            ),
                        };
                    }

                    if (date.month.index === closedDate.getMonth() || date.month.index === closedDate.getMonth() + 1) {
                        handleMouseEnter()
                    }
                    else {
                        handleMouseLeave()
                    }

                    let controlNumber =
                        JSON.stringify(date.day).length === 1 ? "0" + date.day : date.day;
                    let controlMonth =
                        JSON.stringify(date.month.number).length === 1
                            ? "0" + date.month.number
                            : date.month.number;
                    let controlYear = date.year;

                    const objCalendar =
                        dayProduct[controlYear + "-" + controlMonth + "-" + controlNumber];
                    if (objCalendar !== undefined && objCalendar.price !== "null") {
                        if (
                            controlYear + "-" + controlMonth + "-" + controlNumber ===
                            objCalendar.data
                        ) {
                            return {
                                children: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            fontSize: "14px",
                                        }}
                                        onClick={() => { setValueCalendar(objCalendar); handleDateTour(objCalendar) }}
                                    >
                                        <div style={{
                                            textAlign: "center",
                                            position: "absolute",
                                            bottom: "0px",
                                            left: "0px",
                                            right: "0px",
                                            top: "0px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center",

                                        }}>
                                            {date.format("D")}
                                        </div>
                                        {/* <div
                                            style={{
                                                textAlign: "center",
                                                fontSize: "9px",
                                                minHeight: "14px",
                                            }}
                                        >
                                            {" "}
                                            {objCalendar !== undefined
                                                ? objCalendar.price !== "null"
                                                    ? `R$ ${objCalendar.price
                                                        .toFixed(2)
                                                        .replace(".", ",")}`
                                                    : ""
                                                : ""}
                                        </div> */}
                                    </div>
                                ),
                            };
                        } else {
                            return {
                                disabled: true,
                            };
                        }
                    } else {
                        return {
                            disabled: true,
                        };
                    }
                }}
            >
                {
                    showMessage
                        ?
                        <div className="alert-calendar-double message">
                            {
                                i18next.language === "pt"
                                    ?
                                    <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                        <h5>Atenção!</h5>
                                        <p>
                                            O Parque Bondinho Pão de Açúcar® não abrirá no dia 21 de setembro.
                                            As vendas já estão encerradas para essa data, mas não fique triste.
                                            Teremos muitos outros dias lindos para passarmos juntos.
                                            Enquanto isso, acompanhe a gente por aqui ou nas nossas redes sociais.
                                        </ p>
                                    </div>
                                    :
                                    i18next.language === "en"
                                        ?
                                        <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                            <h5 style={{ fontWeight: "bolder" }}>Attention!</h5>
                                            <p style={{ fontSize: "12px" }}>
                                                Parque Bondinho Pão de Açúcar® will be closed on September 21st.
                                                Sales for this date have already ended, but don’t be sad.
                                                We have many other beautiful days to spend together.
                                                In the meantime, stay connected with us here or on our social media.

                                            </p>
                                        </div>
                                        :
                                        i18next.language === "es"
                                            ?
                                            <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                                <h5 style={{ fontWeight: "bolder" }}>¡Aviso!</h5>
                                                <p style={{ fontSize: "12px" }}>
                                                    El Parque Bondinho Pão de Açúcar® no abrirá el 21 de septiembre.
                                                    Las ventas para esta fecha ya han finalizado, pero no se ponga triste.
                                                    Tendremos muchos otros días hermosos para disfrutar juntos.
                                                    Mientras tanto, acompáñenos por aquí o en nuestras redes sociales.
                                                </p>
                                            </div>
                                            :
                                            <div className="p-3" style={{ backgroundColor: "#ebebeb", borderRadius: "20px" }}>
                                                <h5 style={{ fontWeight: "bolder" }}>Atenção!</h5>
                                                <p className="" style={{ fontSize: "12px" }}>O Parque Bondinho Pão de Açúcar® não abrirá no dia 21 de setembro.
                                                    As vendas já estão encerradas para essa data, mas não fique triste.
                                                    Teremos muitos outros dias lindos para passarmos juntos.
                                                    Enquanto isso, acompanhe a gente por aqui ou nas nossas redes sociais.</p>
                                            </div>
                            }
                        </div>
                        :
                        ""
                }


            </DatePicker>
        );
    } else {
        return <></>;
    }
};

export default DoubleCalendar;
