import React from "react";

import { useTranslation } from "react-i18next";

import i18next from "i18next";

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";

import deleteIcon from '../../../assets/icons/icon-garbage.png';
import GetPickupsSIG from "../../C2Points/GetPickupsSIG";

export interface propAction {
  product: any;
  indexP: any;
  alert: any;
  addPickup: any;
}

const CheckoutCardTour: React.FC<propAction> = ({ product, indexP, alert, addPickup }: propAction) => {
  let totalCart = 0;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  if (product !== null) {
    let priceProduct = (
      (Number(product.adults) * Number(product.priceAdults))
      + (Number(product.childs) * Number(product.priceChilds))
      + (Number(product.infants) * Number(product.priceInfants))
      + (Number(product.elders) * Number(product.priceElders))
      + (Number(product.student) * Number(product.priceStudent))
      + (Number(product.priceGlobalPeople))
    );
    totalCart = Number(totalCart) + (priceProduct - (priceProduct * (product.discount / 100)));

    return (
      <div>
        <div
          className="card my-4 rounded"
          data-product="product"
          id={product.product_code}
        >
          <div className="row p-3 pb-0">
            {/* Image */}
            <div className="col-12 col-md-3 mb-0 mb-md-3">
              <div className="">
                <img
                  src={product.imagesBaseUrl + product.imgCart}
                  className="card-img"
                  alt="..."
                />
              </div>
            </div>
            {/* Image */}
            {/* Content */}
            <div className="col-12 col-md-9">
              <div className="row checkout-product-unit">
                <div className="col-12 order-2 order-md-1 pt-3 pt-md-0">
                  <h6 className="text-uppercase">
                    {
                      product.productCode === process.env.REACT_APP_SUPPLIER_BEACH
                        ?
                        <a href={`experiencia/${i18next.language === "pt-BR" ? product.slugBR : i18next.language === "en" ? product.slugEN : i18next.language === "es" ? product.slugES : product.slugBR}`} title="">
                          {i18next.language === "pt-BR" ? product.productNameBR : i18next.language === "en" ? product.productNameEN : i18next.language === "es" ? product.productNameES : product.productNameBR}
                        </a>
                        :
                        <a href={`tour/${i18next.language === "pt-BR" ? product.slugBR : i18next.language === "en" ? product.slugEN : i18next.language === "es" ? product.slugES : product.slugBR}`} title="">
                          {i18next.language === "pt-BR" ? product.productNameBR : i18next.language === "en" ? product.productNameEN : i18next.language === "es" ? product.productNameES : product.productNameBR}
                        </a>
                    }
                  </h6>
                  <p>
                    <small>
                      {t("checkoutCards.cardTour.data")}:{" "}
                      {product.date.split("-").reverse().join("/")}{" "} {product?.hours?.length !== 1 || product?.reservationSystem === 99 || product.productCode === process.env.REACT_APP_SUPPLIER_BEACH ? product?.time?.split(":")[0] + ":" + product?.time?.split(":")[1] : ""}{" "}
                    </small>
                  </p>
                </div>
                <div className="close-btn">
                  <button
                    onClick={() => {
                      dispatch(removeItemCart(product));
                    }}

                  ><img src={deleteIcon} alt="delete" />
                  </button>
                </div>
              </div>

              <div className="mb-3">
                {
                  product.reservationSystem === 99
                    ?
                    <GetPickupsSIG
                      propsId={product.pickupListId}
                      actionPickup={addPickup}
                      alert={alert}
                      index={indexP}
                    />
                    :
                    ""
                }
              </div>
            </div>
            {/* Content */}
          </div>
          <div className="card-bottom bg-black-10 px-3 py-3">
            <div className="row preco align-items-center">
              <div className="col-12 col-md-10">
                <small>
                  {
                    product.ranges.labelsRealSorted.map((faixa: any, i: any) => {
                      if (faixa !== "Private") {
                        return (
                          <span key={i}>
                            {(product[`${faixa.toLowerCase()}`] | product[`${faixa.toLowerCase()}s`]) === 0 ? "" : ` ${product.ranges[`label${faixa}`]}: ${product[`${faixa.toLowerCase()}`] | product[`${faixa.toLowerCase()}s`]} | `}
                          </span>
                        )
                      } else {
                        return (
                          <span key={i}>
                            {product[`totalPeople`] > 0 ? ` ${product.ranges[`labelPrivate`]}: ${product[`totalPeople`]} | ` : ""}
                          </span>
                        )
                      }
                    })}
                  <strong>
                    {t("checkoutCards.cardTour.total_pessoas")}: {product.adults + product.childs + product.infants + product.elders + product.student + (product.totalPeople || 0)}
                  </strong>
                </small>

              </div>
              <div className="col-12 col-md-2 text-right">
                <span className="h6 text-muted">R$</span>
                <span className="pl-1 h5 text-primary font-weight-bold">
                  {(totalCart).toFixed(2).split(".").join(",")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  } else {
    return <></>;
  }
};

export default CheckoutCardTour;

