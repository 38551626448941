import { useEffect, useState } from "react";
import i18next from "i18next";
import Helmet from 'react-helmet';

import Accordion from "react-bootstrap/Accordion";
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Row, Col } from "react-bootstrap";

import { Link as LinkRoll } from "react-scroll";

import "./Tour.scss";

import api from "../../services/api";

import ProductSlider from "../../components/Sliders/ProductSlider/ProductSlider";
import TourOptions from "../../components/ProductOptions/TourOptions";

import CardTourSide from "../../components/CardTourSide/CardTourSide";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { addItemCart } from "../../store/cart";

import PageDafault from "../../templates/PageDafault";

import TagManager from 'react-gtm-module';

import Location from "../../components/Location/Location";
import AcessRules from "../../components/AccessRules/AccessRules";

import DiscountRulesTour from "./components/DiscountRulesTour/DiscountRulesTour";
import DiscountRulesTourEN from "./components/DiscountRulesTour/DiscountRulesTourEN";
import DiscountRulesTourES from "./components/DiscountRulesTour/DiscountRulesTourES";
import DiscountRulesTourMaravilha from "./components/DiscountRulesTourMaravilha/DiscountRulesTourMaravilha";
import DiscountRulesTourMaravilhaEN from "./components/DiscountRulesTourMaravilha/DiscountRulesTourMaravilhaEN";
import DiscountRulesTourMaravilhaES from "./components/DiscountRulesTourMaravilha/DiscountRulesTourMaravilhaES";

import C2rio from '../../assets/img/c2rio.svg';
import TourSigxSig from "./TourSigxSig";
import ModalApiError from "../../components/Modal/ModalApiError/ModalApiError";

const Tour = () => {
  const { t } = useTranslation();
  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

  const [tourResponse, setTourResponse] = useState<any>("null");
  const [optionTour, setOptionTour] = useState<any>([]);
  const [cardTourSide, setCardTourSide] = useState<any>([]);
  const [alert, setAlert] = useState<any>(false);
  const [apiErrorMessage, setApiErrorMessage] = useState<string>('');

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

  const dispatch = useAppDispatch();

  const url = window.location.href;
  const codeUrl = url.split("/");
  const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];
  const isTrilha = codeUrl[4].split("?")[1];

  const [langSelect, setLangSelect] = useState<any>(lng);
  const [count, setCount] = useState<number>(1);

  const addToCart = (itemOption: any, productCode: any) => {

    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartBPA.dados.forEach((elem: any) => {
      if (elem.productCode === productCode) {
        repeatedItem = true
      }
    });

    if (repeatedItem === true) {
      setAlert(true);
    } else {
      const item = {
        productName: tourResponse.productName,
        productNameBR: tourResponse.productNameBR,
        productNameEN: tourResponse.productNameEN,
        productNameES: tourResponse.productNameES,
        imagesBaseUrl: tourResponse.imagesBaseUrl,
        imgCart: tourResponse.images[0],
        price: itemOption?.tarif?.price,

        idTarif: itemOption.idTarif,
        idPickup: itemOption.idPickup,

        priceAdults: itemOption.priceAdults,
        priceChilds: itemOption.priceChilds,
        priceInfants: itemOption.priceInfants,
        priceElders: 0,
        priceStudent: 0,
        priceGlobalPeople: itemOption.priceGlobalPeople,
        reservationSystem: tourResponse.reservationSystem,

        ranges: itemOption.ranges,
        hours: itemOption.hours,

        sellingType: tourResponse.idSellingType,
        adults: itemOption.adults,
        childs: itemOption.childs,
        infants: itemOption.infants,
        elders: itemOption.elders,
        student: itemOption.student,
        globalPeople: itemOption.globalPeople,
        productType: 1,
        productCode: tourResponse.productCode,
        productModCode: itemOption.productModCode,
        productSlug: slugUrl,
        slugBR: tourResponse.slugBR,
        slugEN: tourResponse.slugEN,
        slugES: tourResponse.slugES,
        time: itemOption.time,
        date: itemOption.date,
        supplier: tourResponse.supplier,
        typePickup: itemOption.embarkingType,
        meetingPoint: itemOption.embarkingType === "0" ? itemOption.meetingPoint : '',
        pickupListId: itemOption.embarkingType === "0" ? 0 : itemOption.pickupListId,
        pickup: 0,

        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: "null",
        commingSource: "null",
        latOrigem: "null",
        lngOrigem: "null",
        latDestino: "null",
        lngDestino: "null",
        cia: "null",
        voo: "null",
        smallSuitcase: 0,
        bigSuitcase: 0,
        internalNotes: " ",
        idVeiculo: 0,
        passengers: [],

        channel: isTrilha === 'trilha' ? 7 : undefined,
      }

      //SetCartItem(item);
      dispatch(addItemCart(item));
      window.location.href = window.location.origin + '/checkout';
    }
  }

  function getSlugFromUrl(url: any) {
    const parsedUrl = new URL(url);
    const path = parsedUrl.pathname;
    const segments = path.split('/');
    const slug: any = segments.pop() || segments.pop();
    return slug.split('&')[0];
  }

  const slugChange = getSlugFromUrl(url);

  useEffect(() => {
    let idChannel: number = 2;
    async function getItems() {
      try {
        const res = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTourAsync`,
          {
            productCode: "",
            lang: langSelect,
            Slug: `${slugChange.replaceAll("%20", " ").replaceAll("%C3%A9", "é")}`,
            idCanal: isTrilha === "trilha" ? 7 : idChannel
          }
        );
        if (res.status !== 400) {
          if (res.data.statusCode === 204) {
            if (count <= 3) {
              if (res.data.data === null && (i18next.language === "pt-BR" || i18next.language === 'pt')) {
                setCount(count + 1);
                setLangSelect('EN');
                i18next.changeLanguage('en');
              } else if (res.data.data === null && i18next.language === "en") {
                setCount(count + 1);
                setLangSelect('ES');
                i18next.changeLanguage('es');
              } else {
                setCount(count + 1);
                setLangSelect('BR');
                i18next.changeLanguage('pt');
              }
            } else {
              window.location.href = "/404"
            }
          } else if (res.data.statusCode === 200) {
            setTourResponse(res.data?.data);
            setOptionTour(res.data?.data?.modalities);
            setCardTourSide(res.data?.data?.modalities);

            if (res.data.data?.modalities?.length === 0) {
              //ERRO PRODUTO SEM MODALIDADES
              setApiErrorMessage(t('modalApiError.notFoundModality'));
            }
          } else {
            //ERRO STATUS CODE 400
            setApiErrorMessage(res.data?.errorMessage || t('modalApiError.sorry'));
          }
        } else {
          //ERRO STATUS 400
          setApiErrorMessage(res.data?.errorMessage || t('modalApiError.sorry'));
        }
      } catch (error: any) {
        //ERRO
        setApiErrorMessage(error?.response?.data?.errorMessage || t('modalApiError.errorAPI'));
      }
    }

    const config = {
      headers: { "ngrok-skip-browser-warning": "69420" },
    };

    async function getIdChannel() {
      try {
        const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
        if (data.statusCode === 200) {
          idChannel = data.data.data;
        }
        getItems();
      } catch (error) {
        getItems();
      }
    }

    getIdChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langSelect]);

  useEffect(() => {
    const newSlug = (i18next.language === "pt" ? tourResponse.slugBR : i18next.language === "en" ? tourResponse.slugEN : i18next.language === "es" ? tourResponse.slugES : tourResponse.slugBR);
    if (slugUrl !== newSlug && newSlug !== undefined) {
      window.location.href = window.location.origin + "/tour/" + newSlug;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    if (tourResponse !== "null") {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            detail: {
              products: [{
                name: tourResponse.productName,         // Name or ID is required.
                id: tourResponse.productCode,
                price: tourResponse.modalities[0]?.tarif?.price,
                brand: 'Bondinho',
              }]
            }
          }
        }
      });
    }
  }, [tourResponse]);

  if (tourResponse !== "null") {
    const ldJson = {
      "@context": "http://schema.org/",
      "@type": "TouristAttraction",
      "description": tourResponse.productInfo.replace(/<[^>]*>?/gm, ''),
      "image": tourResponse.imagesBaseUrl + '/medium_' + tourResponse.images[0],
      "name": process.env.REACT_APP_SERVER_NAME,
      "url": process.env.REACT_APP_SERVER_URL
    };

    return (
      <>
        <Helmet>
          <html lang={i18next.language} />
          <title>{tourResponse.productName + ' | Parque Bondinho'}</title>
          <meta name="description" content={tourResponse.productDescription.replace(/<[^>]*>?/gm, '')} />
          <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

          {/* Essential META Tags */}
          <meta property="og:title" content={tourResponse.productName + ' | Parque Bondinho'} />
          <meta property="og:type" content="TouristAttraction" />
          <meta property="og:image" content={`${tourResponse.imagesBaseUrl}/medium_${tourResponse.images[0]}`} />
          <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

          {/* Non-Essential, But Recommended */}
          <meta property="og:description" content={tourResponse.productDescription.replace(/<[^>]*>?/gm, '')} />
          <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" /> */}

          {
            tourResponse.productCode === "PXY4929" || tourResponse.productCode === "PXC8157" || tourResponse.productCode === "PXE9656"
              ?
              <meta name="robots" content="noindex, nofollow" />
              :
              <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
          }


          <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

          <script type="application/ld+json">
            {JSON.stringify(ldJson)}
          </script>
        </Helmet>

        {
          tourResponse.reservationSystem !== 99
            ?
            <PageDafault>
              <div className="bg-light">
                <nav className="navbar bg-light d-none" id="navbar-destino-top">
                  <ul className="nav container">
                    <li className="nav-item dropdown ">
                      <div
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      ></div>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      ></div>
                    </li>
                  </ul>
                </nav>
                <div className="container destino text-dark bg-light">
                  <div className="row ">
                    <div
                      className="col-12 col-lg-9 order-2 order-sm-2 order-md-1 px-0 px-md-3"
                      data-spy="scroll"
                      data-target="#navbar-destino"
                      data-offset="0"
                    >
                      <div className="col-12 p-4">
                        {/* Carrosel de imagens */}
                        <section>
                          <div id="carousel-thumb">
                            <ProductSlider arrayImages={tourResponse.images} imagesBaseUrl={tourResponse.imagesBaseUrl} />
                          </div>
                        </section>
                        {/* Carrosel de imagens */}

                        {/* Navegador Tour */}
                        <div className="conteudo">
                          <nav
                            id="navbar-destino"
                            className="navbar navbar-light bg-white mb-5"
                          >
                            <div
                              className="container text-center p-0 m-0"
                              style={{ display: "block" }}
                            >
                              <ul className="nav-pills scroll-nav">
                                <Row>
                                  <Col md={9} className="flex-column">
                                    <Row>
                                      <Col md={3}>
                                        <li className="nav-item">
                                          <LinkRoll
                                            activeClass="active"
                                            to="session-experience"
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                          >
                                            <span className="nav-link link-tour nav-tour">
                                              {" "}
                                              <p className="d-md-inline d-lg-inline text-md-uppercase">
                                                <FontAwesomeIcon
                                                  icon={["fal", "grin-wink"]}
                                                  size="1x"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                <span>{t("tour.theExperience")}</span>
                                              </p>
                                            </span>
                                          </LinkRoll>
                                        </li>
                                      </Col>
                                      <Col md={3}>
                                        <li className="nav-item">
                                          <LinkRoll
                                            activeClass="active"
                                            to="modalidades"
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                          >
                                            <span className="nav-link link-tour nav-tour">
                                              {" "}
                                              <p className="d-lg-inline text-md-uppercase">
                                                <FontAwesomeIcon
                                                  icon={["fal", "list"]}
                                                  size="1x"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                <span>{t("tour.options")}</span>
                                              </p>
                                            </span>
                                          </LinkRoll>
                                        </li>
                                      </Col>
                                      <Col md={3}>
                                        <li className="nav-item">
                                          <LinkRoll
                                            activeClass="active"
                                            to="session-info"
                                            spy={true}
                                            smooth={true}
                                            offset={-140}
                                            duration={500}
                                          >
                                            <span className="nav-link link-tour nav-tour">
                                              {" "}
                                              <p className="d-lg-inline text-md-uppercase">
                                                <FontAwesomeIcon
                                                  icon={["fal", "info-circle"]}
                                                  size="1x"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                <span>{t("tour.Information")}</span>
                                              </p>
                                            </span>
                                          </LinkRoll>
                                        </li>
                                      </Col>
                                      <Col md={3}>
                                        <li className="nav-item">
                                          <LinkRoll
                                            activeClass="active"
                                            to="session-contact"
                                            spy={true}
                                            smooth={true}
                                            offset={-80}
                                            duration={500}
                                          >
                                            <span className="nav-link link-tour nav-tour">
                                              {" "}
                                              <p className="d-lg-inline text-md-uppercase">
                                                <FontAwesomeIcon
                                                  icon={["fal", "phone-rotary"]}
                                                  size="1x"
                                                  style={{ marginRight: "5px" }}
                                                />
                                                <span>{t("tour.contact")}</span>
                                              </p>
                                            </span>
                                          </LinkRoll>
                                        </li>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item">
                                      <LinkRoll
                                        className="nav-link link-tour nav-tour btn-see-options"
                                        activeClass="active"
                                        to="modalidades"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={500}
                                        style={{
                                          backgroundColor: "#FF6600",
                                          width: "135px",
                                          marginLeft: "auto",
                                        }}
                                      >
                                        {t("tour.buyBtn")}
                                      </LinkRoll>
                                    </li>
                                  </Col>
                                </Row>
                              </ul>
                            </div>
                          </nav>
                          {/* Navegador Tour */}

                          <div className="row d-flex align-content-end">
                            <div className="col-md-12 py-2">
                              {
                                tourResponse.reservationSystem === 99
                                  ?
                                  <div className="bagde-sig">
                                    <FontAwesomeIcon
                                      icon={["fas", "circle"]}
                                      size="5x"
                                      style={{ fontSize: ".5em", color: "#FF6600" }}
                                    />
                                    <h6 style={{ fontSize: "1rem" }} className="mb-0 d-flex align-items-center gap-1">Operado por: <img src={C2rio} height="22px" alt='logo C2rio' /></h6>
                                  </div>
                                  :
                                  ""
                              }
                              <h1 className="h1 font-weight-bold pb-2 text-primary" id="name-product">
                                {tourResponse.productName}
                              </h1>
                            </div>
                          </div>

                          {/* Conteúdo do passeio */}

                          <h5
                            className="text-uppercase text-primary"
                            id="session-experience"
                          >
                            <FontAwesomeIcon
                              icon={["fal", "smile"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            {t("tour.theExperience")}
                          </h5>
                          <span
                            id="description"
                            dangerouslySetInnerHTML={{
                              __html: tourResponse.productDescription,
                            }}
                          ></span>

                          <h5 className="text-uppercase text-primary mt-2">
                            <FontAwesomeIcon
                              icon={["fal", "info-circle"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            {t("tour.informations")}
                          </h5>
                          <span
                            id="infos-product"
                            dangerouslySetInnerHTML={{
                              __html: tourResponse.productInfo,
                            }}
                          ></span>

                          <h5 className="text-uppercase text-primary mt-2">
                            <FontAwesomeIcon
                              icon={["fal", "star"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            {t("tour.highlights")}{" "}
                          </h5>
                          <ul id="highlights">
                            {tourResponse.highlights.map((h: any, index: any) => {
                              return <li key={index}> {h} </li>;
                            })}
                          </ul>

                          <h5 className="text-uppercase text-primary mt-2">
                            <FontAwesomeIcon
                              icon={["fal", "star"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            {t("tour.details")}
                          </h5>
                          <ul id="details-product">
                            {tourResponse.details.map((d: any, index: any) => {
                              return <li key={index}> {d} </li>;
                            })}
                          </ul>
                          {/* Conteúdo do passeio */}

                          {/* Opções do passeio */}
                          <section
                            id="modalidades"
                            className=" my-3"
                            data-destine="modalidades"
                          >
                            <h5 className="text-uppercase text-primary pb-4">
                              <FontAwesomeIcon
                                icon={["fal", "list"]}
                                size="1x"
                                style={{ marginRight: "5px" }}
                              />
                              {t("tour.options")}
                            </h5>
                            <TourOptions
                              options={optionTour}
                              response={tourResponse}
                              action={addToCart}
                            ></TourOptions>
                          </section>
                          {/* Opções do passeio */}

                          <div className="accordion accordion-rules" id="info">
                            {/* {
                          tourResponse.productCode === process.env.REACT_APP_MARAVILHA
                            ?
                            <Accordion defaultActiveKey="0">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <span
                                    className="h4 text-primary font-weight-normal text-uppercase"
                                    id="infos"
                                  >
                                    {t("tour.rules.fathersRulesTitle")}
                                  </span>
                                  <i className="fal float-right p-0"></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {
                                    i18next.language === "pt"
                                      ?
                                      <PromotionRulesPT />
                                      :
                                      i18next.language === "en"
                                        ?
                                        <PromotionRulesEN />
                                        :
                                        i18next.language === "es"
                                          ?
                                          <PromotionRulesES />
                                          :
                                          <PromotionRulesPT />
                                  }
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            :
                            ""
                        } */}
                            <Accordion defaultActiveKey="0" className="mt-4">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <span
                                    className="h4 text-primary font-weight-normal text-uppercase"
                                    id="infos"
                                  >
                                    {t("tour.discountRules")}
                                  </span>
                                  <i className="fal float-right p-0"></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                  {
                                    tourResponse.productCode !== process.env.REACT_APP_MARAVILHA
                                      ?
                                      <>
                                        {
                                          i18next.language === "pt"
                                            ?
                                            <DiscountRulesTour />
                                            :
                                            i18next.language === "en"
                                              ?
                                              <DiscountRulesTourEN />
                                              :
                                              i18next.language === "es"
                                                ?
                                                <DiscountRulesTourES />
                                                :
                                                <DiscountRulesTour />
                                        }
                                      </>
                                      :
                                      <>
                                        {
                                          i18next.language === "pt"
                                            ?
                                            <DiscountRulesTourMaravilha />
                                            :
                                            i18next.language === "en"
                                              ?
                                              <DiscountRulesTourMaravilhaEN />
                                              :
                                              i18next.language === "es"
                                                ?
                                                <DiscountRulesTourMaravilhaES />
                                                :
                                                <DiscountRulesTourMaravilha />
                                        }
                                      </>
                                  }
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mt-4">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <span
                                    className="h4 text-primary font-weight-normal text-uppercase"
                                    id="politica"
                                  >
                                    {t("navHeader.sideBar.howtoget")}
                                  </span>
                                  <i className="fal float-right p-0"></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <Location />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mt-4">
                              <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                  <span
                                    className="h4 text-primary font-weight-normal text-uppercase"
                                    id="politica"
                                  >
                                    {t("navHeader.sideBar.acessRules")}
                                  </span>
                                  <i className="fal float-right p-0"></i>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <AcessRules />
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                            {/* <Accordion defaultActiveKey="0" className="mt-4">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <span
                                className="h4 text-primary font-weight-normal text-uppercase"
                                id="politica"
                              >
                                {t("tour.cancel")}
                              </span>
                              <i className="fal float-right p-0"></i>
                            </Accordion.Header>
                            <Accordion.Body>
                              
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion> */}
                          </div>

                          <div className="my-4">
                            <div className="row m-0 overflow-hidden">
                              <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                                <h4 id="questoes" className="py-0">
                                  {" "}
                                  {t("tour.code")}?
                                </h4>
                              </div>

                              <div className="col-12 col-sm-6 col-md- col-lg- col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                                <span id="product-code">
                                  {t("tour.code")}: {tourResponse.productCode}
                                </span>
                              </div>
                            </div>
                            <div id="session-contact" className="row mt-4">
                              <div className="col-12 text-center">
                                <p>{t("tour.emergencyContacts")}</p>
                              </div>
                            </div>
                            <div className="row text-center justify-content-evenly">
                              {/* <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                            <FontAwesomeIcon
                              icon={["fal", "phone-alt"]}
                              size="3x"
                            />

                            <strong className="d-none">
                              {t("tour.telephone")} :
                            </strong>
                            <span className="d-block py-2" id="tel-info">
                              {process.env.REACT_APP_INFO_PHONE}
                            </span>
                            <a
                              href={`tel:${process.env.REACT_APP_INFO_PHONE}`}
                              title=""
                              className="btn btn-sm btn-outline-primary btn-contact"
                            >
                              {t("tour.callPhone")}
                            </a>
                          </div> */}
                              <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                                <FontAwesomeIcon
                                  icon={["fab", "whatsapp"]}
                                  size="3x"
                                />
                                <strong className="d-none">WhatsApp:</strong>
                                <span className="d-block py-2" id="whats-info">
                                  {process.env.REACT_APP_INFO_WHATSAPP}
                                </span>
                                <a
                                  href={`https://api.whatsapp.com/send?phone=55${process.env.REACT_APP_INFO_WHATSAPP?.replaceAll("(21) ", "21").replaceAll("-", "")}`}
                                  title=""
                                  className="btn btn-sm btn-outline-primary btn-contact"
                                >
                                  {t("tour.messageWpp")}
                                </a>

                                <small className="d-block mt-1" style={{ fontSize: ".75rem" }}>{t("tour.belowMessageWpp")}</small>
                              </div>
                              <div className="col-12 col-sm-4 col-md- pb-4 mb-md-0">
                                <FontAwesomeIcon
                                  icon={["fal", "envelope"]}
                                  size="3x"
                                />

                                <strong className="d-none">{t("tour.email")}:</strong>
                                <span className="d-block py-2" id="mail-info">
                                  {" "}
                                  {process.env.REACT_APP_INFO_EMAIL}
                                </span>
                                <a
                                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}`}
                                  title=""
                                  className="btn btn-sm btn-outline-primary btn-contact"
                                >
                                  {t("tour.sendEmail")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3 order-2 order-sm-2 order-md-1 d-none d-lg-block">
                      <CardTourSide options={cardTourSide} />
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                className="modal-confirm"
                show={alert}
                onHide={() => {
                  setAlert(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <div className='modal-body text-center'>
                  <div>
                    <FontAwesomeIcon
                      icon={["fal", "times-circle"]}
                      size="5x"
                      style={{ fontSize: "7.5em", color: "#FF6600" }}
                    />

                  </div>
                  <div className="px-3 px-md-5">
                    {t("tour.alertRepeatedItem")}
                  </div>
                  <div>
                    <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlert(false)}>Fechar</Button>
                  </div>
                </div>
              </Modal>
            </PageDafault>
            :
            <TourSigxSig />
        }
        <ModalApiError apiErrorMessage={apiErrorMessage} setApiErrorMessage={setApiErrorMessage} />

      </>
    );
  } else {
    return (
      <>
        <PageDafault>
          <div className="bg-light">
            <div className="container destino text-dark bg-light px-0">
              <div className="row w-100 m-0">
                <div
                  className="col-12 col-lg-9 order-2 order-lg-1 px-0"
                >
                  <div className="col-12 p-4">
                    <div className="animated-background w-100" style={{ height: "clamp(350px, 35vw, 700px)" }}></div>

                    <div>
                      <nav
                        id="navbar-destino"
                        className="navbar navbar-light bg-white mb-5"
                      >
                        <div
                          className="container text-center p-0 m-0"
                          style={{ display: "block" }}
                        >
                          <ul className="nav-pills scroll-nav">
                            <Row>
                              <Col md={9} className="flex-column">
                                <Row>
                                  <Col md={3}>
                                    <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                      <span style={{ height: "40px" }}></span>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                      <span style={{ height: "40px" }}></span>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                      <span style={{ height: "40px" }}></span>
                                    </li>
                                  </Col>
                                  <Col md={3}>
                                    <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                      <span style={{ height: "40px" }}></span>
                                    </li>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md={3}>
                                <li className="nav-item animated-background" style={{ height: "40px" }}>
                                  <span style={{ height: "40px" }}></span>
                                </li>
                              </Col>
                            </Row>
                          </ul>
                        </div>
                      </nav>
                      {/* Navegador Tour */}

                      <div className="row d-flex align-content-end mt-5 mb-4">
                        <div className="animated-background w-50" style={{ height: "56px" }}></div>
                      </div>

                      {/* Conteúdo do passeio */}
                      <h5 className="text-uppercase text-primary" id="experiencia">
                        <FontAwesomeIcon
                          icon={["fal", "smile"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.theExperience")}
                      </h5>
                      <span
                        className="animated-background"
                        style={{ height: "200px", display: "block" }}
                      ></span>

                      <h5 className="text-uppercase text-primary mt-2">
                        <FontAwesomeIcon
                          icon={["fal", "info-circle"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.informations")}
                      </h5>
                      <span
                        className="animated-background"
                        style={{ height: "200px", display: "block" }}
                      ></span>
                      <section
                        id="modalidades"
                        className=" my-3"
                        data-destine="modalidades"
                      >
                        <h5 className="text-uppercase text-primary pb-4">
                          <FontAwesomeIcon
                            icon={["fal", "list"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t("tour.options")}
                        </h5>
                        <TourOptions
                          options={optionTour}
                          response={tourResponse}
                          action={addToCart}
                        ></TourOptions>
                      </section>
                      {/* Opções do passeio */}

                      <div className="accordion accordion-rules" id="info">
                        <div className="animated-background" style={{ height: "44.8px" }}></div>
                        <div className="animated-background mt-4" style={{ height: "44.8px" }}></div>
                        <div className="animated-background mt-4" style={{ height: "44.8px" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-3 order-1 order-lg-2">
                  <CardTourSide options={cardTourSide} />
                </div>
              </div>
            </div>
          </div>
        </PageDafault>
        <ModalApiError apiErrorMessage={apiErrorMessage} setApiErrorMessage={setApiErrorMessage} />
      </>
    );
  }
};

export default Tour;
